<template>
    <v-app class="align-center d-flex">
        <v-app-bar
                app
                class="bg-main d-flex  align-center d-lg-none d-xl-none d-md-none d-sm-block"
                dark >
            <div class="d-flex justify-start">
                <v-btn icon width="25" class="mr-4" :to="'/myassets'">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </div>
            <div class="d-flex justify-center align-center" style="width: 100%">
                <v-toolbar-title align="center" class="font-weight-regular">Select currency</v-toolbar-title>
            </div>
        </v-app-bar>
        <v-main class="bg-main" style="padding:40px 0">
            <v-container>
                <template>
                    <v-card
                            class="mx-auto"
                            max-width="500"
                            :dark="true"
                            elevation="0"
                    >


                        <v-list>
                            <v-list-item-group>
                                <template v-for="(item, index) in items">
                                    <v-list-item :key="item.title" :to="'/deposit'">
                                        <template>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="item.title"></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>

                                                <v-icon  >
                                                    mdi-chevron-right
                                                </v-icon>
                                            </v-list-item-action>
                                        </template>
                                    </v-list-item>

                                    <v-divider
                                            v-if="index <= items.length - 1"
                                            :key="index"
                                    ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </template>
            </v-container>
        </v-main>
    </v-app>
</template>

<style>
    /*.theme--dark.v-list {*/
    /*    background: #1a212a;*/
    /*}*/
</style>

<script>
    export default {
        data: () => ({
            selected: [2],
            items: [
                {
                    title: 'BNB',
                },
                {
                    title: 'BTC',
                },
                {
                    title: 'BSV',
                },
                {
                    title: 'DASH',
                },
                {
                    title: 'EOS',
                },
                {
                    title: 'ETH',
                },
                {
                    title: 'EUSDT',
                },
                {
                    title: 'LTC',
                },
                {
                    title: 'XPR',
                },
                {
                    title: 'TUSDT',
                },
                {
                    title: 'YFI',
                },

            ],
        }),
    }
</script>