<template>

    <v-app>
        <v-system-bar
                :dark="true"
                color="primary"
                height="26"
        ></v-system-bar>
        <v-app-bar
                app
                class="bg-main d-lg-none d-xl-none d-md-none d-sm-block"
                dark>
            <div class="d-flex align-center">
                <v-img
                        alt="Vuetify Logo"
                        class="shrink mr-2"
                        contain
                        src="../assets/img/exc-logo.png"
                        transition="scale-transition"
                        width="40"
                />

                <v-img
                        alt="Vuetify Name"
                        class="shrink mt-1 hidden-sm-and-down"
                        contain
                        min-width="100"
                        src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
                        width="100"
                />
            </div>
            <v-spacer></v-spacer>
            <v-btn href="#"  text :to="'/message'" >
                <span class="mr-2"></span>
                <v-icon color="rgba(255,255,255,0.7)">mdi-message-processing</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main class="bg-main">
            <router-view/>
        </v-main>
        <v-bottom-navigation :dark="true" :fixed="true"
                             class="bg-main justify-space-around"
                             color="rgb(240, 167, 10)">
            <v-btn value="home" to="dashboard" width="60">
                <span class="font-weight-regular">Home</span>
                <v-icon>mdi-home</v-icon>
            </v-btn>
            <v-btn value="market" to="market" width="60">
                <span>Market</span>
                <v-icon>mdi-rug</v-icon>
            </v-btn>
            <v-btn value="trade" to="trade">
                <span>Trade</span>
                <v-icon>mdi-strategy</v-icon>
            </v-btn>
            <v-btn value="swap" to="swap">
                <span>Swap</span>
                <v-icon>mdi-swap-horizontal-variant</v-icon>
            </v-btn>
            <v-btn value="option" to="option">
                <span>Option</span>
                <v-icon>mdi-multicast</v-icon>
            </v-btn>
            <v-btn value="my" to="profile">
                <span>My</span>
                <v-icon>mdi-account</v-icon>
            </v-btn>
        </v-bottom-navigation>


    </v-app>
</template>

<style>
    .v-btn:before {
        background-color: transparent;
    }
</style>


<script>
    // import HelloWorld from './components/HelloWorld';

    export default {
        name: 'App',

        // components: {
        //   HelloWorld,
        // },

        data: () => ({
            //
        }),
    };
</script>
