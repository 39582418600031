<template>
    <v-app>
        <v-card
                class="overflow-hidden bg-main" :dark="true"
                height="100%"
        >
            <v-app-bar
                    color="bg-main"
                    :dark="true"
                    flat >
                <v-btn icon >
                    <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
                </v-btn>

                <v-toolbar-title>BTC/USDT</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn
                        icon
                        light
                >
                    <v-icon color="white darken-2" class="">
                        mdi-star-outline
                    </v-icon>
                    <v-icon color="white darken-2" class="mr-5 ml-2">
                        mdi-chart-line
                    </v-icon>
                </v-btn>




                <!--                     <v-icon>mdi-menu-open</v-icon>-->

            </v-app-bar>

            <v-navigation-drawer :dark="true" class="bg-main"
                                 v-model="drawer"
                                 width="350px"
                                 absolute
                                 temporary
            >
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6">
                           Trade
                        </v-list-item-title>

                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>



                    <v-tabs
                            align-with-title
                            v-model="tab4"
                            color="#f0a70a"
                            :dark="true"
                            background-color="transparent"
                            class="bg-main text--white d-flex justify-center align-center"

                            grow>
                        <v-tab
                                v-for="item4 in items4"
                                :key="item4"
                                style="font-size: 0.8rem !important;"
                        >
                            {{ item4 }}
                        </v-tab>
                    </v-tabs>


                <v-tabs-items v-model="tab4">
                    <v-tab-item
                            v-for="item4 in items4"
                            :key="item4"
                    >

                        <v-simple-table style="border:1px solid transparent;max-width: 100%">
                            <tbody>
                            <tr>
                                <td  class=" pt-3 pb-3">
                                   <p class="font-weight-bold text-body-1 mb-0" style="color: white">BTC</p>
                                </td>
                                <td  class="pt-3 pb-3" align="center">
                                    <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0</p>
                                </td>
                                <td  class="pt-3 pb-3" align="right">
                                    <v-avatar
                                            color="#00b275"
                                            rounded
                                            size="65"
                                            height="25"
                                            style="color: white"
                                    >+0.00%</v-avatar>
                                </td>
                            </tr>
                            <tr>
                                <td  class=" pt-3 pb-3">
                                   <p class="font-weight-bold text-body-1 mb-0" style="color: white">ETH</p>
                                </td>
                                <td  class="pt-3 pb-3" align="center">
                                    <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0</p>
                                </td>
                                <td  class="pt-3 pb-3" align="right">
                                    <v-avatar
                                            color="#00b275"
                                            rounded
                                            size="65"
                                            height="25"
                                            style="color: white"
                                    >+0.00%</v-avatar>
                                </td>
                            </tr>
                            <tr>
                                <td  class=" pt-3 pb-3">
                                   <p class="font-weight-bold text-body-1 mb-0" style="color: white">XPR</p>
                                </td>
                                <td  class="pt-3 pb-3" align="center">
                                    <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0</p>
                                </td>
                                <td  class="pt-3 pb-3" align="right">
                                    <v-avatar
                                            color="#00b275"
                                            rounded
                                            size="65"
                                            height="25"
                                            style="color: white"
                                    >+0.00%</v-avatar>
                                </td>
                            </tr>
                            <tr>
                                <td  class=" pt-3 pb-3">
                                   <p class="font-weight-bold text-body-1 mb-0" style="color: white">EOS</p>
                                </td>
                                <td  class="pt-3 pb-3" align="center">
                                    <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0</p>
                                </td>
                                <td  class="pt-3 pb-3" align="right">
                                    <v-avatar
                                            color="#00b275"
                                            rounded
                                            size="65"
                                            height="25"
                                            style="color: white"
                                    >+0.00%</v-avatar>
                                </td>
                            </tr><tr>
                                <td  class=" pt-3 pb-3">
                                   <p class="font-weight-bold text-body-1 mb-0" style="color: white">LTC</p>
                                </td>
                                <td  class="pt-3 pb-3" align="center">
                                    <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0</p>
                                </td>
                                <td  class="pt-3 pb-3" align="right">
                                    <v-avatar
                                            color="#00b275"
                                            rounded
                                            size="65"
                                            height="25"
                                            style="color: white"
                                    >+0.00%</v-avatar>
                                </td>
                            </tr>
                            

                            </tbody>
                        </v-simple-table>
                    </v-tab-item>
                </v-tabs-items>
            </v-navigation-drawer>


            <v-main class="bg-main">

                <v-container>
                    <v-row v-resize="onResize">
                        <v-col class="col-7">
                            <div class="row pl-2">
                                <div class="col-6 pb-0">
                                    <p class="text--white mb-0" style="font-size: 0.8rem">USDT Account</p>
                                </div>
                                <div class="col-6 pb-0">
                                    <p class="text--white  text-center mb-0" style="font-size: 0.8rem">Cross <v-icon class="text--white">mdi-menu-down</v-icon>
                                    </p>
                                </div>
                                <div class="col-6">
                                    <v-btn
                                            block
                                            elevation="2"
                                            class="text-capitalize rounded-0"
                                            outlined color="green"
                                    >Long</v-btn>
                                </div>
                                <div class="col-6">
                                    <v-btn
                                            class="text-capitalize rounded-0"
                                            block
                                            elevation="2"
                                            outlined color="green"
                                    >Short</v-btn>
                                </div>
                                <v-col
                                        class="pl-3 pt-0 pb-0 mb-0"
                                        cols="8"
                                        sm="8"
                                >
                                    <v-select
                                            :items="items2"
                                            label="Market Price"
                                            dense
                                            class="rounded-0"
                                            single-line
                                            color="white" :dark="true"
                                            style="font-size: 0.7rem;height: 49px !important;"
                                            outlined
                                    ></v-select>
                                </v-col>
                                <v-col
                                        class="pl-0 pt-0 pb-0 mb-0"
                                        cols="4"
                                        sm="4"
                                >
                                    <v-select

                                            dense
                                            single-line
                                            label="100%"
                                            class="rounded-0"
                                            color="white" :dark="true"
                                            style="font-size: 0.8rem; padding-right: 1px;height: 49px !important; "
                                            @click="dialog = true"
                                            outlined
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" sm="12" class="pt-0 pb-0 mb-0">
                                    <v-text-field
                                            label="Trigger Price"
                                            outlined
                                            :dark="true"
                                            single-line
                                            class="rounded-0"
                                            color="white"
                                            dense
                                            style="height: 49px !important;"
                                    >Trigger Price
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" class="pt-0 pr-0">
                                    <v-row>
                                        <v-col cols="7" sm="7" class="pr-0">
                                            <v-text-field
                                                    label="Amount"
                                                    outlined
                                                    class="rounded-0"
                                                    :dark="true"
                                                    single-line
                                                    color="white"
                                                    dense
                                                    type="number"
                                                    style="height: 40px !important;">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="5" sm="5" class="pl-0 d-flex">
                                            <v-btn
                                                    :dark="true"
                                                    color="white"
                                                    fab
                                                    style="height: 40px !important;width:45% !important;max-width:100% !important;min-width: auto !important; border-left: 0px solid transparent;border-top: 1px solid rgb(86, 86, 86);border-bottom: 1px solid rgb(86, 86, 86);border-right: 1px solid rgb(86, 86, 86);"
                                                    outlined
                                                    small
                                                    tile
                                            ><v-icon style="font-size: 23px !important;">mdi-plus</v-icon></v-btn>
                                            <v-btn
                                                    :dark="true"
                                                    class="text--white"
                                                    fab
                                                    style="height: 40px !important;width: 45% !important;max-width:100% !important;min-width: auto !important;border-left: 1px solid rgb(86, 86, 86);border-right: 1px solid rgb(86, 86, 86);border-top: 1px solid rgb(86, 86, 86);border-bottom: 1px solid rgb(86, 86, 86);"
                                                    outlined
                                                    small
                                                    tile
                                            ><v-icon class="text--white" style="font-size: 23px !important;">mdi-minus</v-icon></v-btn>


                                        </v-col>
                                    </v-row>

                                </v-col>
                                <v-col cols="12" sm="12" class="pt-0 pb-0 mb-0">
                                    <v-text-field
                                            v-model="fruits"
                                            label="Amount"
                                            outlined
                                            :dark="true"
                                            single-line
                                            class="rounded-0"
                                            color="white"
                                            dense
                                            style="height: 49px !important;"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" class="pt-0 pb-0 mb-0">
                                    <p class="text-body-2 text--white pb-0 mb-0" align="right">1 Cont = 100 BTC</p>
                                </v-col>
                                <v-col cols="12" sm="12" >
                                    <v-slider
                                            :dark="true"
                                            v-model="fruits"
                                            :tick-labels="ticksLabels"
                                            :max="4"
                                            step="1"
                                            ticks="always"
                                            tick-size="5"
                                    ></v-slider>
                                </v-col>
                                <v-col cols="6" sm="6" class="pt-0 pb-3 mb-0">
                                    <small class="text--white" align="left">Avail 500868.29</small>
                                </v-col>
                                <v-col cols="6" sm="6" class="pt-0 pb-3 mb-0 text-right">
                                    <small class="text--white text-right" align="right"><span style="color: rgb(0, 178, 117)">Max. L:</span> 12 Cont</small>
                                </v-col>
                                <v-col cols="12" sm="12" class="pb-10">
                                    <v-btn
                                            block
                                            color="#00b275"
                                            class="rounded-0 text--white"
                                            large
                                    >OPEN LONG</v-btn>
                                </v-col>
                            </div>
                        </v-col>
                        <v-col class="col-5 pl-0 pr-2">
                            <div class="row pl-0 pr-2">
                                <v-simple-table style="border: 1px solid transparent !important; width: 100%" class="bg-main">
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-left text-body-1 text--white" style="color: rgb(255,255,255)">
                                                Price <br>
                                                (USDT)
                                            </th>
                                            <th align="right" class="text-body-1 pr-2 text--white text-right" style="color: rgb(255,255,255)">
                                                Amount <br>(BTC)
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td style="color:#d62321;height: 34px !important;font-size: 0.8rem">--</td>
                                            <td class="text--white" style="height: 34px !important;font-size: 0.8rem" align="right">--</td>
                                        </tr>
                                        <tr>
                                            <td style="color:#d62321;height: 34px !important;font-size: 0.8rem">--</td>
                                            <td class="text--white"  style="height: 34px !important;" align="right">--</td>
                                        </tr>
                                        <tr>
                                            <td style="color:#d62321;height: 34px !important;font-size: 0.8rem">--</td>
                                            <td class="text--white" style="height: 34px !important;font-size: 0.8rem" align="right">--</td>
                                        </tr>
                                        <tr>
                                            <td style="color:#d62321;height: 34px !important;font-size: 0.8rem">--</td>
                                            <td class="text--white" style="height: 34px !important;font-size: 0.8rem" align="right">--</td>
                                        </tr>
                                        <tr>
                                            <td style="color:#d62321;height: 34px !important;font-size: 0.8rem">--</td>
                                            <td class="text--white" style="height: 34px !important;font-size: 0.8rem" align="right">--</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" class="pt-2">
                                                <p style="color: #00b275;font-size: 1.3rem;" class="pb-0 mb-0">00000.00</p>
                                                <p class="text--white">= 000000.00 CNY</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="color: #00b275;height: 34px !important;font-size: 0.8rem">--</td>
                                            <td class="text--white" style="height: 34px !important;font-size: 0.8rem" align="right">--</td>
                                        </tr>
                                        <tr>
                                            <td style="color: #00b275;height: 34px !important;font-size: 0.8rem">--</td>
                                            <td class="text--white"  style="height: 34px !important;font-size: 0.8rem" align="right">--</td>
                                        </tr>
                                        <tr>
                                            <td style="color: #00b275;height: 34px !important;font-size: 0.8rem">--</td>
                                            <td class="text--white" style="height: 34px !important;font-size: 0.8rem" align="right">--</td>
                                        </tr>
                                        <tr>
                                            <td style="color: #00b275;height: 34px !important;font-size: 0.8rem">--</td>
                                            <td class="text--white" style="height: 34px !important;font-size: 0.8rem" align="right">--</td>
                                        </tr>
                                        <tr>
                                            <td style="color: #00b275;height: 34px !important;font-size: 0.8rem">--</td>
                                            <td class="text--white" style="height: 34px !important;font-size: 0.8rem" align="right">--</td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>



                <v-divider style="border-width: 4px;border-color: #13161d;"></v-divider>
                <v-row>
                    <v-col cols="10" sm="10">
                        <v-tabs v-model="tab1"
                                color="#f0a70a"
                                class="bg-main text-capitalize"
                                grow>
                            <v-tab v-for="item1 in items1"
                                   :key="item1" class="text-capitalize">{{ item1 }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <v-col cols="2" sm="2">
                        <v-subheader style="color:#f0a70a;">All</v-subheader>
                    </v-col>
                </v-row>


                <v-tabs-items v-model="tab1">
                    <v-tab-item
                            v-for="item1 in items1"
                            :key="item1"
                    >
                        <v-container class="pa-0">

                            <v-simple-table class="" style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-around">
                                        <th class="text-center text-body-1 pt-3 text--white" colspan="2" style="color: rgb(255,255,255)">
                                            PL <br>
                                            <p  style="font-size: 0.9rem;color: #ce4850">-14937260.9998 USDT</p>
                                        </th>
                                        <th class="text-center  text-body-1 pt-3 text--white" colspan="2" style="color: rgb(255,255,255)">
                                            PL Ratio<br>
                                            <p color="#ce4850" style="font-size: 0.9rem;color: #ce4850">-2130850.36%</p>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>

                                        <td  class="text-center">
                                            <p class="mb-1 text--white" style="font-size:0.7rem;">Leverage</p>
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="70"
                                                    height="20"
                                                    style="color: white;font-size: 0.7rem"

                                            >Long 100x</v-avatar>
                                        </td>
                                        <td  class="text-center">
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;">Position</p>
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.3)">701Cont</p>
                                        </td>
                                        <td  class="text-center">
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;">Ava Position</p>
                                            <p class="mb-0"  style="font-size: 0.7rem;color: rgba(255,255,255,0.3)">701Cont</p>
                                        </td>
                                        <td  class="text-center">
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;">Cur Margin</p>
                                            <p class="mb-0" style="font-size: 0.7rem;color: rgba(255,255,255,0.3)">701.00USDT</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td  class="text-center mt-2">
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;">Margin Ratio</p>
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.3)">-21307.50%</p>
                                        </td>
                                        <td  class="text-center mt-2" colspan="2">
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;">Open Price</p>
                                            <p class="mb-0"  style="font-size: 0.7rem;color: rgba(255,255,255,0.3)">38059.4550USDT</p>
                                        </td>
                                        <td  class="text-center mt-2">
                                            <v-btn class="ml-2 text-capitalize text--white bg-yellow" small >
                                                Close
                                            </v-btn>
                                        </td>
                                    </tr>

                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-simple-table class="pb-16" style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-around">
                                        <th class="text-center text-body-1 pt-3 text--white" colspan="2" style="color: rgb(255,255,255)">
                                            PL <br>
                                            <p  style="font-size: 0.9rem;color: #00b275">-14937260.9998 USDT</p>
                                        </th>
                                        <th class="text-center  text-body-1 pt-3 text--white" colspan="2" style="color: rgb(255,255,255)">
                                            PL Ratio<br>
                                            <p style="font-size: 0.9rem;color: #00b275">-2130850.36%</p>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>

                                        <td  class="text-center">
                                            <p class="mb-1 text--white" style="font-size:0.7rem;">Leverage</p>
                                            <v-avatar
                                                    color="#ce4850"
                                                    rounded
                                                    size="70"
                                                    height="20"
                                                    style="color: white;font-size: 0.7rem"

                                            >Long 100x</v-avatar>
                                        </td>
                                        <td  class="text-center">
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;">Position</p>
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.3)">701Cont</p>
                                        </td>
                                        <td  class="text-center">
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;">Ava Position</p>
                                            <p class="mb-0"  style="font-size: 0.7rem;color: rgba(255,255,255,0.3)">701Cont</p>
                                        </td>
                                        <td  class="text-center">
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;">Cur Margin</p>
                                            <p class="mb-0" style="font-size: 0.7rem;color: rgba(255,255,255,0.3)">701.00USDT</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td  class="text-center mt-2">
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;">Margin Ratio</p>
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.3)">-21307.50%</p>
                                        </td>
                                        <td  class="text-center mt-2" colspan="2">
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;">Open Price</p>
                                            <p class="mb-0"  style="font-size: 0.7rem;color: rgba(255,255,255,0.3)">38059.4550USDT</p>
                                        </td>
                                        <td  class="text-center mt-2">
                                            <v-btn class="ml-2 text-capitalize text--white bg-yellow" small >
                                                Close
                                            </v-btn>
                                        </td>
                                    </tr>

                                    </tbody>
                                </template>
                            </v-simple-table>


                        </v-container>


                    </v-tab-item>
                </v-tabs-items>
            </v-main>
        </v-card>





                        <v-bottom-navigation :dark="true" :fixed="true"
                                             class="bg-main justify-space-around"
                                             color="rgb(240, 167, 10)">
                            <v-btn value="home" to="dashboard" width="60">
                                <span class="font-weight-regular">Home</span>
                                <v-icon>mdi-home</v-icon>
                            </v-btn>
                            <v-btn value="market" to="market" width="60">
                                <span>Market</span>
                                <v-icon>mdi-rug</v-icon>
                            </v-btn>
                            <v-btn value="trade" to="trade">
                                <span>Trade</span>
                                <v-icon>mdi-strategy</v-icon>
                            </v-btn>
                            <v-btn value="swap" to="swap">
                                <span>Swap</span>
                                <v-icon>mdi-swap-horizontal-variant</v-icon>
                            </v-btn>
                            <v-btn value="option" to="option">
                                <span>Option</span>
                                <v-icon>mdi-multicast</v-icon>
                            </v-btn>
                            <v-btn value="my" to="profile">
                                <span>My</span>
                                <v-icon>mdi-account</v-icon>
                            </v-btn>
                        </v-bottom-navigation>

    </v-app>
</template>

<style>
    .theme--dark.v-tabs > .v-tabs-bar {
        background-color: #1a212a;
    }
    .main-tab-counter  .v-tab{
        min-width: 43px !important;
    }
    /*.v-slide-group__content {*/
    /*    border-bottom: 2px solid black;*/
    /*}*/
    .v-label {
        font-size: 11px;
    }
    .v-input__icon {
        align-items: center;
        display: inline-flex;
        height: 24px;
        flex: 1 0 auto;
        justify-content: center;
        min-width: 8px;
        width: 2px;
    }
    .v-dialog--fullscreen {
        border-radius: 0;
        margin: 0;
        height: auto !important;
        position: fixed;
        overflow-y: auto;
        top: auto !important;
        left: 0;
        bottom: 0 !important;
    }
    .v-slider__tick-label{
        font-size: 11px !important;
    }
    .v-slider--horizontal .v-slider__track-container {
        height: 6px !important;
    }
    .v-slider__tick{
        width: 12px !important;
        height: 12px !important;
        top: calc(56% - 6.5px) !important;
        background: #fff !important;
        border-radius: 50% !important;
        line-height: 2 !important;
    }
    .v-slider__thumb {
        width: 16px !important;
        height: 16px !important;
    }
    .theme--dark.v-slider .v-slider__track-background {
        background: rgb(255, 255, 255) !important;
    }
    .v-slider__thumb::after {
        content: "";
        width: 17px !important;
        height: 16px !important;
        background: #00b275 !important;
        border-radius: 50% !important;
    }
    .v-slider--horizontal .v-slider__tick .v-slider__tick-label {
        top: 14px !important;
    }
    .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child, .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child {
        border-bottom: thin solid transparent;
    }
    .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
        border-bottom: thin solid transparent;
    }
    .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
        border-bottom: thin solid transparent;
    }
</style>

<script>
    export default {
        data: () => ({
            dialog: false,
            drawer: false,
            group: null,
            tab: null,
            tab1:null,
            tab4:null,
            value:[
                '0',
                '10',
                '50',
                '75',
                '100'
            ],
            fruits: [
                '0',
                '10',
                '50',
                '75',
                '100'
            ],
            ticksLabels: [
                '0%',
                '10%',
                '50%',
                '75%',
                '100%'
            ],
            items: [
                'HISTORY', 'KLINE',
            ],

            items1: [
                'Current Position', 'Current Entrust',
            ],
            items2: ['Market Price', 'Market Price', 'Market Price', 'Market Price'],

            items4: [
                'FAVOR', 'USDT', 'BTC', 'ETH',
            ],


        }),


    }

</script>