<template>
    <v-app>
        <v-card
                class="overflow-hidden bg-main" :dark="true"
                height="100%"
        >
            <v-app-bar
                    color="bg-main"
                    :dark="true"
                    flat >
                <router-link to="dashboard" class="text-decoration-none">
                    <v-icon class="mr-4">mdi-chevron-left</v-icon>
                </router-link>
                <v-toolbar-title>My Assets</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu transition="scroll-y-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                style="background-color: transparent !important; font-size: 0.8rem"
                                color="secondary"
                                v-bind="attrs"
                                v-on="on"
                                elevation="0"  class="text-capitalize font-weight-regular">
                                The detail
                        </v-btn>
                    </template>
                    <v-list :dark="true" style="padding: 2px 25px 6px 11px">
                        <v-list-item class="flex-column justify-center align-center d-block">
                            <v-list-item-title  style="min-height: 30px;font-size: 0.8rem;text-transform:capitalize"><router-link to="rechargerecord" class="text-decoration-none text--white">Recharge
                                Record</router-link></v-list-item-title>
                            <v-list-item-title style="min-height: 30px;font-size: 0.8rem;text-transform: capitalize"><router-link to="withdrawrecord" class="text-decoration-none text--white">Withdraw Record</router-link></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <!--                     <v-icon>mdi-menu-open</v-icon>-->
            </v-app-bar>
            <v-main class="bg-main">
                    <v-col cols="12" sm="12">
                        <v-card class="mx-auto"
                                max-width="400">
                            <v-img
                                    class="white--text"
                                    height="160px"
                                    src="../assets/img/assets-bg.png"
                            >
<!--                                <v-subheader class="text-capitalize font-weight-regular">Total Assets (USDT)</v-subheader>-->
                                <v-card-title class="text-capitalize font-weight-regular text-body-1 pb-0">Total Assets (USDT) <v-icon class="ml-2">mdi-eye </v-icon></v-card-title>
                                <v-card-title class="text-capitalize font-weight-bold text-h5 mt-0 pt-0">0.00000000</v-card-title>
                            </v-img>
                        </v-card>
                    </v-col>
                <v-row
                        align="center"
                        justify="space-around"
                        class="pt-2"
                >
                    <v-btn
                    color="#1f2833"
                    elevation="0"
                    to="selectcurrency"
                    class="rounded-0 text-capitalize"
                    >
                        Deposite
                    </v-btn>
                    <v-btn
                            color="#1f2833"
                            to="withdrawcurrency"
                            elevation="0"
                            class="rounded-0 text-capitalize"
                    >
                        Withdraw
                    </v-btn>
                    <v-btn
                            to="assettransfer"
                            color="#1f2833"
                            elevation="0"
                            class="rounded-0 text-capitalize"
                    >
                        Transfer
                    </v-btn>


                </v-row>

                <v-row>
                    <v-col cols="12" sm="12">
                        <v-tabs v-model="tab1"
                                color="#f0a70a"
                                class="bg-main text-capitalize"
                                grow
                                left
                                style="border-bottom: 2px solid rgba(0,0,0,0.1);"

                        >
                            <v-tab v-for="item1 in items1"
                                   :key="item1" class="text-capitalize">{{ item1 }}
                            </v-tab>
                        </v-tabs>
                    </v-col>


                </v-row>

                <v-tabs-items v-model="tab1">
                    <v-tab-item
                            v-for="item1 in items1"
                            :key="item1"
                    >

                        <v-container class="pa-0">

                            <div  class="row bg-main">
                                <v-col cols="8" sm="8" class="pt-0 pl-2 pb-0 mb-0">

                                    <v-text-field
                                            :dark="true"
                                            solo
                                            class="mt-0 pt-3 mb-0 pb-0 ml-2 text--white"
                                            label="Prepend"
                                            style="margin-bottom: -20px !important;"
                                            prepend-inner-icon="mdi-magnify">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" class="pt-0 mt-0 mb-0 pb-0">
                                    <v-checkbox :dark="true"
                                                class="  pb-0 mb-0"
                                                label="Hide Zero"
                                                color="warning"
                                                value="warning"
                                                hide-details>
                                    </v-checkbox>
                                </v-col>
                            </div>
                            <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                            <v-simple-table class="" style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-between">
                                        <th class="text-body-1 pt-3 font-weight-bold" colspan="3" style="color: rgb(255,255,255);border: 1px solid transparent">
                                           BNB
                                        </th>
                                    </tr>

                                    </thead>

                                    <tbody>
                                    <tr class="justify-space-between">
                                        <td>
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>
                                        <td class="text-center">
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>

                                    </tr>


                                    </tbody>
                                </template>

                            </v-simple-table>
                            <v-simple-table class="" style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-between">
                                        <th class="text-body-1 pt-3 font-weight-bold" colspan="1" style="color: rgb(255,255,255);border: 1px solid transparent">
                                           BTC
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="justify-space-between">
                                        <td>
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>
                                        <td class="text-center">
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>

                                    </tr>


                                    </tbody>
                                </template>

                            </v-simple-table>
                            <v-simple-table class="" style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-between">
                                        <th class="text-body-1 pt-3 font-weight-bold" colspan="1" style="color: rgb(255,255,255);border: 1px solid transparent">
                                            BCH
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="justify-space-between">
                                        <td>
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>
                                        <td class="text-center">
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>

                                    </tr>


                                    </tbody>
                                </template>

                            </v-simple-table>
                            <v-simple-table class="" style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-between">
                                        <th class="text-body-1 pt-3 font-weight-bold" colspan="1" style="color: rgb(255,255,255);border: 1px solid transparent">
                                            BSV
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="justify-space-between">
                                        <td>
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>
                                        <td class="text-center">
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>

                                    </tr>


                                    </tbody>
                                </template>

                            </v-simple-table>
                            <v-simple-table class="" style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-between">
                                        <th class="text-body-1 pt-3 font-weight-bold" colspan="1" style="color: rgb(255,255,255);border: 1px solid transparent">
                                            BZB
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="justify-space-between">
                                        <td>
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>
                                        <td class="text-center">
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>

                                    </tr>


                                    </tbody>
                                </template>

                            </v-simple-table>
                            <v-simple-table class="" style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-between">
                                        <th class="text-body-1 pt-3 text--white" colspan="1" style="color: rgb(255,255,255);border: 1px solid transparent">
                                            BNB
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="justify-space-between">
                                        <td>
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>
                                        <td class="text-center">
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>

                                    </tr>


                                    </tbody>
                                </template>

                            </v-simple-table>
                            <v-simple-table class="" style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-between">
                                        <th class="text-body-1 pt-3 font-weight-bold" colspan="1" style="color: rgb(255,255,255);border: 1px solid transparent">
                                            DASH
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="justify-space-between">
                                        <td>
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>
                                        <td class="text-center">
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-0"  style="font-size:0.7rem;color: rgba(255,255,255,0.5);border: 1px solid transparent">Available</p>
                                            <p class="mb-0 text--white" style="font-size: 0.7rem;" >300.0000</p>
                                        </td>

                                    </tr>


                                    </tbody>
                                </template>

                            </v-simple-table>




                        </v-container>


                    </v-tab-item>
                </v-tabs-items>
            </v-main>
        </v-card>

    </v-app>
</template>

<style>
    .theme--dark.v-tabs > .v-tabs-bar {
        background-color: #1a212a;
    }
    .main-tab-counter  .v-tab{
        min-width: 43px !important;
    }
    /*.v-slide-group__content {*/
    /*    border-bottom: 2px solid black;*/
    /*}*/
    .v-label {
        font-size: 11px;
    }
    .v-input__icon {
        align-items: center;
        display: inline-flex;
        height: 24px;
        flex: 1 0 auto;
        justify-content: center;
        min-width: 8px;
        width: 2px;
    }
    .v-dialog--fullscreen {
        border-radius: 0;
        margin: 0;
        height: auto !important;
        position: fixed;
        overflow-y: auto;
        top: auto !important;
        left: 0;
        bottom: 0 !important;
    }
    .v-slider__tick-label{
        font-size: 11px !important;
    }
    .v-slider--horizontal .v-slider__track-container {
        height: 6px !important;
    }
    .v-slider__tick{
        width: 12px !important;
        height: 12px !important;
        top: calc(56% - 6.5px) !important;
        background: #fff !important;
        border-radius: 50% !important;
        line-height: 2 !important;
    }
    .v-slider__thumb {
        width: 16px !important;
        height: 16px !important;
    }
    .theme--dark.v-slider .v-slider__track-background {
        background: rgb(255, 255, 255) !important;
    }
    .v-slider__thumb::after {
        content: "";
        width: 17px !important;
        height: 16px !important;
        background: #00b275 !important;
        border-radius: 50% !important;
    }
    .v-slider--horizontal .v-slider__tick .v-slider__tick-label {
        top: 14px !important;
    }
    .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child, .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child {
        border-bottom: thin solid transparent;
    }
    .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
        border-bottom: thin solid transparent;
    }
    .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
        border-bottom: thin solid transparent;
    }
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
        box-shadow:0px 3px 1px -2px transparent !important;
    }
    .theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
        background: transparent;
    }
    .v-application--is-ltr .v-text-field .v-input__prepend-inner {
        padding-right: 12px !important;
    }
    /*.v-input__slot{*/
    /*    margin-bottom: -20px !important;*/
    /*}*/

</style>

<script>
    export default {
        data: () => ({
            dialog: false,
            drawer: false,
            group: null,
            tab: null,
            tab1:null,
            value:[
                '0',
                '10',
                '50',
                '75',
                '100'
            ],
            fruits: [
                '0',
                '10',
                '50',
                '75',
                '100'
            ],
            ticksLabels: [
                '0%',
                '10%',
                '50%',
                '75%',
                '100%'
            ],
            items: [
                'HISTORY', 'KLINE',
            ],

            items1: [
                'Currency Account', 'Swap Account',
            ],
            items2:
                ['Market Price', 'Market Price', 'Market Price', 'Market Price'],

                items3: [
                    { title: 'Recharge Record' },
                    { title: 'Withdraw Record' },
                ],
        }),


    }

</script>