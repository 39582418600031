<template>
    <v-app class="justify-center align-center d-flex">
        <v-app-bar
                app
                class="bg-main d-flex justify-center align-center d-lg-none d-xl-none d-md-none d-sm-block"
                dark >
            <div class="d-flex align-center justify-center">
                <v-toolbar-title class="text-center">Market</v-toolbar-title>
            </div>
        </v-app-bar>

        <v-main class="bg-main">
                    <v-tabs
                            v-model="tab"
                            color="#f0a70a"
                            :dark="true"
                            background-color="transparent"
                            class="bg-main text--white"
                            grow >
                        <v-tab
                                v-for="item in items"
                                :key="item"
                        >
                            {{ item }}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item
                                v-for="item in items"
                                :key="item"
                        >

                            <v-simple-table style="border:1px solid transparent">


                                    <tbody>
                                    <tr>

                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">BTC</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>

                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">ETH</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">XPR</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">BCH</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">BSV</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">LTC</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">EOS</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">YFH</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">YFI</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">NFO</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">BTC</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">ETH</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">XPR</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">BCH</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">BSV</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">LTC</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">EOS</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">YFH</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">YFI</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem"><span style="color: #fff">NFO</span>/USDT</p>
                                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">24H
                                                    0.0000000</small></div>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00 CNY</small>
                                        </td>
                                        <td  class="text-center pt-3 pb-3">
                                            <v-avatar
                                                    color="#00b275"
                                                    rounded
                                                    size="65"
                                                    height="25"
                                                    style="color: white"
                                            >+0.00%</v-avatar>
                                        </td>

                                    </tr>
                                    </tbody>
                            </v-simple-table>
                        </v-tab-item>
                    </v-tabs-items>
        </v-main>
        <v-bottom-navigation :dark="true" :fixed="true"
                             class="bg-main justify-space-around"
                             color="rgb(240, 167, 10)">
            <v-btn value="home" to="dashboard" width="60">
                <span class="font-weight-regular">Home</span>
                <v-icon>mdi-home</v-icon>
            </v-btn>
            <v-btn value="market" to="market" width="60">
                <span>Market</span>
                <v-icon>mdi-rug</v-icon>
            </v-btn>
            <v-btn value="trade" to="trade">
                <span>Trade</span>
                <v-icon>mdi-strategy</v-icon>
            </v-btn>
            <v-btn value="swap" to="swap">
                <span>Swap</span>
                <v-icon>mdi-swap-horizontal-variant</v-icon>
            </v-btn>
            <v-btn value="option" to="option">
                <span>Option</span>
                <v-icon>mdi-multicast</v-icon>
            </v-btn>
            <v-btn value="my" to="profile">
                <span>My</span>
                <v-icon>mdi-account</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-app>
</template>

<style>
   /*.v-window {*/
   /* height: auto !important;*/
   /*}*/
   .v-data-table{
       border-radius: 0;
   }
</style>

<script>
    export default {
        data () {
            return {
                tab: null,
                items: [
                    'FAVOR', 'USDT', 'BTC', 'ETH',
                ],

            }
        },
    }
</script>