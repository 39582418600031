<template>
    <v-app class="align-center d-flex">
        <v-app-bar
                color="bg-main"
                :dark="true"
                fixed
                flat >
            <router-link to="securitysetting" class="text-decoration-none">
                <v-icon class="mr-4">mdi-chevron-left</v-icon>
            </router-link>
            <v-toolbar-title>Change login password</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- Modal-->
                                <v-btn icon light
                                       v-bind="attrs"
                                       v-on="on" class="text-capitalize" style="color: #f0a70a">
                                Save
                                </v-btn>
            <!--  Modal end-->
        </v-app-bar>
        <v-main style="background-color: #13161d;" class="pt-10">
            <v-container>
                <v-row class="ma-0">
                    <v-col cols="12" sm="12" style="padding: 0">
                        <v-card class="mx-auto bg-main pt-0 mt-0 rounded" max-width="100%"   outlined  >
                            <v-list-item  three-line style="min-height:20px"     class="">
                                <v-col cols="6" sm="6" class="pa-0">
                                    <v-list-item-content class="pa-0">
                                        <v-list-item-title style="color: white">
                                            Old login <br>
                                            password
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-col>

                                <v-col cols="6" sm="6" class="pa-0 ma-0 pass-main">
                                    <v-text-field
                                            solo
                                            class="pa-0 ma-0"
                                            v-model="password"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required, rules.min]"
                                            :type="show1 ? 'text' : 'password'"
                                            name="input-10-1"
                                            placeholder="Old login password"
                                            :dark="true"
                                            color="#f0a70a"
                                            @click:append="show1 = !show1"
                                    ></v-text-field>
                                </v-col>
                            </v-list-item>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" style="padding: 0">
                        <v-card class="mx-auto bg-main pt-0 mt-0 rounded" max-width="100%"   outlined  >
                            <v-list-item  three-line style="min-height:20px"     class="">
                                <v-col cols="6" sm="6" class="pa-0">
                                    <v-list-item-content class="pa-0">
                                        <v-list-item-title style="color: white">
                                            New login <br>
                                            password
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-col>

                                <v-col cols="6" sm="6" class="pa-0 ma-0 pass-main">
                                    <v-text-field
                                            solo
                                            class="pa-0 ma-0"
                                            v-model="password"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required, rules.min]"
                                            :type="show1 ? 'text' : 'password'"
                                            name="input-10-1"
                                            placeholder="New login password"
                                            :dark="true"
                                            color="#f0a70a"
                                            @click:append="show1 = !show1"
                                    ></v-text-field>
                                </v-col>
                            </v-list-item>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" style="padding: 0">
                        <v-card class="mx-auto bg-main pt-0 mt-0 rounded" max-width="100%"   outlined  >
                            <v-list-item  three-line style="min-height:20px"     class="">
                                <v-col cols="6" sm="6" class="pa-0">
                                    <v-list-item-content class="pa-0">
                                        <v-list-item-title style="color: white">
                                            Confirm <br>
                                            password
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-col>

                                <v-col cols="6" sm="6" class="pa-0 ma-0 pass-main">
                                    <v-text-field
                                            solo
                                            class="pa-0 ma-0"
                                            v-model="password"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required, rules.min]"
                                            :type="show1 ? 'text' : 'password'"
                                            name="input-10-1"
                                            placeholder="Confirm password"
                                            :dark="true"
                                            color="#f0a70a"
                                            @click:append="show1 = !show1"
                                    ></v-text-field>
                                </v-col>
                            </v-list-item>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" style="padding: 0">
                        <v-card class="mx-auto bg-main pt-0 mt-0 rounded" max-width="100%"   outlined  >
                            <v-list-item  three-line style="min-height:20px"     class="">
                                <v-col cols="3" sm="3" class="pa-0">
                                    <v-list-item-content class="pa-0">
                                        <v-list-item-title style="color: white">
                                            verification <br>
                                            code
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-col>

                                <v-col cols="9" sm="9" class="pa-0 ma-0 pass-main d-flex justify-center align-center">
                                    <v-text-field
                                            type="number"
                                            solo
                                            class="pa-0 ma-0"
                                            placeholder="Verification code"
                                            :dark="true"
                                            color="#f0a70a"
                                            @click:append="show1 = !show1"
                                    ></v-text-field>
                                    <v-btn
                                            style="border: 1px solid #f0a70a;color: #f0a70a"
                                            outlined
                                            class="text-capitalize pl-1 pr-1"
                                    >Send Verification</v-btn>
                                </v-col>
                            </v-list-item>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<style>
  .pass-main .v-text-field__details{
      display: none;
  }

</style>

<script>
    export default {
        data () {
            return {
                switch2: false,
                show1: true,
                show2: true,
                show3: false,
                show4: false,
                rules: {

                    emailMatch: () => (`The email and password you entered don't match`),
                },
            }
        },
    }
</script>