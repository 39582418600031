<template>
    <v-app>
        <v-card
                class="overflow-hidden bg-main" :dark="true"
                height="100%"
        >
            <v-app-bar
                    color="bg-main"
                    :dark="true"
                    fixed
                    flat >
                <router-link to="myassets" class="text-decoration-none">
                    <v-icon class="mr-4">mdi-chevron-left</v-icon>
                </router-link>
                <v-toolbar-title>Recharge Record</v-toolbar-title>
                <v-spacer></v-spacer>

                <!-- Modal-->
                <template>
                    <v-row justify="end" >
                        <v-col cols="auto">
                            <v-dialog
                                    transition="dialog-top-transition"
                                    max-width="600"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon light
                                           v-bind="attrs"
                                           v-on="on">
                                        <v-icon color="" class="text--white">
                                            mdi-filter
                                        </v-icon>
                                    </v-btn>
                                </template>

                                <v-simple-table class="bg-main"  style="border:1px solid transparent">
                                    <template>
                                        <thead>
                                        <tr>
                                            <th class="text-body-1  font-weight-regular" align="center" style="text-align:center;color: rgb(255,255,255);border: 1px solid transparent">
                                                BNB
                                            </th>
                                        </tr>
                                        <v-divider  style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                                        <tr>
                                            <th class="text-body-1 font-weight-regular" align="center" style="text-align:center;color: rgb(255,255,255);border: 1px solid transparent">
                                                BTC
                                            </th>
                                        </tr>
                                        <v-divider  style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                                        <tr>
                                            <th class="text-body-1 font-weight-regular" align="center" style="text-align:center;color: rgb(255,255,255);border: 1px solid transparent">
                                                DASH
                                            </th>
                                        </tr>
                                        <v-divider  style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                                        <tr>
                                            <th class="text-body-1 font-weight-regular" align="center" style="text-align:center;color: rgb(255,255,255);border: 1px solid transparent">
                                                ETH
                                            </th>
                                        </tr>
                                        <v-divider  style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                                        <tr>
                                            <th class="text-body-1 font-weight-regular" align="center" style="text-align:center;color: rgb(255,255,255);border: 1px solid transparent">
                                                EUSDT
                                            </th>
                                        </tr>
                                        <v-divider  style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                                        <tr>
                                            <th class="text-body-1 font-weight-regular" align="center" style="text-align:center;color: rgb(255,255,255);border: 1px solid transparent">
                                                TUSDT
                                            </th>
                                        </tr>
                                        </thead>
                                    </template>
                                </v-simple-table>
                            </v-dialog>
                        </v-col>
                    </v-row>
                </template>
                <!--  Modal end-->
            </v-app-bar>
            <v-main class="bg-main pt-12">
                <v-container class="pa-0 bg-main">
                    <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                    <v-simple-table class="bg-main"  style="border:1px solid transparent">
                        <template v-slot:default>
                            <thead class="">
                            <tr class="justify-space-between">
                                <th class="text-body-1 pt-3 font-weight-regular"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                    BNB
                                </th>
                                <th></th>
                                <th class="text-body-1 pt-3 font-weight-regular text-right"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                    Done
                                </th>
                            </tr>

                            </thead>

                            <tbody>
                            <tr class="justify-space-between">
                                <td>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Transaction Time</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >2022-22-04 21:10</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Payment fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>
                                <td class="text-center">
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Type</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >Option Reward</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Discount Fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>
                                <td class="text-right">
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Amount</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >199.96</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Actual fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>

                            </tr>
                            </tbody>
                        </template>

                    </v-simple-table>
                    <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                    <v-simple-table class="bg-main"  style="border:1px solid transparent">
                        <template v-slot:default>
                            <thead class="">
                            <tr class="justify-space-between">
                                <th class="text-body-1 pt-3 font-weight-regular"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                    BNB
                                </th>
                                <th></th>
                                <th class="text-body-1 pt-3 font-weight-regular text-right"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                    Done
                                </th>
                            </tr>

                            </thead>

                            <tbody>
                            <tr class="justify-space-between">
                                <td>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Transaction Time</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >2022-22-04 21:10</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Payment fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>
                                <td class="text-center">
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Type</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >Option Reward</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Discount Fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>
                                <td class="text-right">
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Amount</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >199.96</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Actual fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>

                            </tr>
                            </tbody>
                        </template>

                    </v-simple-table>
                    <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                    <v-simple-table class="bg-main"  style="border:1px solid transparent">
                        <template v-slot:default>
                            <thead class="">
                            <tr class="justify-space-between">
                                <th class="text-body-1 pt-3 font-weight-regular"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                    BNB
                                </th>
                                <th></th>
                                <th class="text-body-1 pt-3 font-weight-regular text-right"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                    Done
                                </th>
                            </tr>

                            </thead>

                            <tbody>
                            <tr class="justify-space-between">
                                <td>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Transaction Time</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >2022-22-04 21:10</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Payment fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>
                                <td class="text-center">
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Type</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >Option Reward</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Discount Fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>
                                <td class="text-right">
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Amount</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >199.96</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Actual fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>

                            </tr>
                            </tbody>
                        </template>

                    </v-simple-table>
                    <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                    <v-simple-table class="bg-main"  style="border:1px solid transparent">
                        <template v-slot:default>
                            <thead class="">
                            <tr class="justify-space-between">
                                <th class="text-body-1 pt-3 font-weight-regular"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                    BNB
                                </th>
                                <th></th>
                                <th class="text-body-1 pt-3 font-weight-regular text-right"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                    Done
                                </th>
                            </tr>

                            </thead>

                            <tbody>
                            <tr class="justify-space-between">
                                <td>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Transaction Time</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >2022-22-04 21:10</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Payment fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>
                                <td class="text-center">
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Type</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >Option Reward</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Discount Fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>
                                <td class="text-right">
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Amount</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >199.96</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Actual fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>

                            </tr>
                            </tbody>
                        </template>

                    </v-simple-table>
                    <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                    <v-simple-table class="bg-main"  style="border:1px solid transparent">
                        <template v-slot:default>
                            <thead class="">
                            <tr class="justify-space-between">
                                <th class="text-body-1 pt-3 font-weight-regular"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                    BNB
                                </th>
                                <th></th>
                                <th class="text-body-1 pt-3 font-weight-regular text-right"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                    Done
                                </th>
                            </tr>

                            </thead>

                            <tbody>
                            <tr class="justify-space-between">
                                <td>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Transaction Time</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >2022-22-04 21:10</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Payment fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>
                                <td class="text-center">
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Type</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >Option Reward</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Discount Fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>
                                <td class="text-right">
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Amount</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >199.96</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Actual fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>

                            </tr>
                            </tbody>
                        </template>

                    </v-simple-table>
                    <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                    <v-simple-table class="bg-main"  style="border:1px solid transparent">
                        <template v-slot:default>
                            <thead class="">
                            <tr class="justify-space-between">
                                <th class="text-body-1 pt-3 font-weight-regular"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                    BNB
                                </th>
                                <th></th>
                                <th class="text-body-1 pt-3 font-weight-regular text-right"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                    Done
                                </th>
                            </tr>

                            </thead>

                            <tbody>
                            <tr class="justify-space-between">
                                <td>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Transaction Time</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >2022-22-04 21:10</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Payment fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>
                                <td class="text-center">
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Type</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >Option Reward</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Discount Fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>
                                <td class="text-right">
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Amount</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >199.96</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Actual fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>

                            </tr>
                            </tbody>
                        </template>

                    </v-simple-table>
                    <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                    <v-simple-table class="bg-main"  style="border:1px solid transparent">
                        <template v-slot:default>
                            <thead class="">
                            <tr class="justify-space-between">
                                <th class="text-body-1 pt-3 font-weight-regular"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                    BNB
                                </th>
                                <th></th>
                                <th class="text-body-1 pt-3 font-weight-regular text-right"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                    Done
                                </th>
                            </tr>

                            </thead>

                            <tbody>
                            <tr class="justify-space-between">
                                <td>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Transaction Time</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >2022-22-04 21:10</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Payment fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>
                                <td class="text-center">
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Type</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >Option Reward</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Discount Fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>
                                <td class="text-right">
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Amount</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >199.96</p>
                                    <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Actual fee</p>
                                    <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                </td>

                            </tr>
                            </tbody>
                        </template>

                    </v-simple-table>


                </v-container>
            </v-main>
        </v-card>

    </v-app>
</template>

<style>
    .v-dialog {
        margin: 0 !important;
    }
    .v-dialog__content {
        align-items: start;
        display: flex;
        top: 55px;
    }

</style>

<script>
    export default {
        data: () => ({
            select:null,
            dialog: false,
            drawer: false,
            group: null,
            tab: null,
            tab1:null,
            time: null,
            time3: null,
            menu2: false,
            modal2: false,
            modal3: false,
            menu3: false,
            value:[
                '0',
                '10',
                '50',
                '75',
                '100'
            ],
            fruits: [
                '0',
                '10',
                '50',
                '75',
                '100'
            ],
            ticksLabels: [
                '0%',
                '10%',
                '50%',
                '75%',
                '100%'
            ],
            items: [
                'HISTORY', 'KLINE',
            ],
            items5: [
                'Item 1',
                'Item 2',
                'Item 3',
                'Item 4',
            ],

            items1: [
                'Currency Account', 'Swap Account',
            ],
            items2:
                ['Market Price', 'Market Price', 'Market Price', 'Market Price'],

            items3: [
                { title: 'Recharge Record' },
                { title: 'Withdraw Record' },
            ],
        }),


    }

</script>