<template>
    <v-app>
        <v-card
                class="overflow-hidden bg-main" :dark="true"
                height="100%"
        >
            <v-app-bar
                    style="background-color: #1f2833 !important;"
                    :dark="true"
                    fixed
                    flat >
                <router-link :to="'/dashboard'" class="text-decoration-none">
                    <v-icon class="mr-2">mdi-chevron-left</v-icon>
                </router-link>
                <v-toolbar-title>BTC/USDT</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                        icon
                        light
                >
                    <v-icon class="text--white">
                        mdi-fullscreen
                    </v-icon>
                </v-btn>

                <!--                     <v-icon>mdi-menu-open</v-icon>-->

            </v-app-bar>


            <v-main class="bg-main pt-14">

                <v-container>
                    <v-row v-resize="onResize">

                        <v-col class="col-12">
                            <div class="row">
                                <v-simple-table style="border: 1px solid transparent !important; width: 100%" class="bg-main">
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-h6 pl-5" style="color:#00b275">
                                                0 <br>
                                                <p class="text--white font-weight-regular" style="font-size: 0.9rem">= 0.00 CNY <span style="color: #00b275">0.00%</span></p>
                                            </th>
                                            <th align="right" class="font-weight-regular pr-7 text--white text-right" style="">
                                                <p class="mb-0 pb-1">High <span class="ml-2">0</span></p>
                                                <p class="mb-0 pb-1">Low <span class="ml-2">0</span></p>
                                                <p class="mb-0 pb-1">24 H  <span class="ml-2">0</span></p>
                                            </th>

                                        </tr>
                                        </thead>
                                        <tbody>
<!--                                        <tr>-->
<!--                                            <td class="text&#45;&#45;white" style="height: 34px !important;font-size: 0.8rem">sdfsdf</td><td class="text&#45;&#45;white" style="height: 34px !important;font-size: 0.8rem" align="right">sdfsdf</td>-->
<!--                                        </tr>-->
                                        </tbody>
                                    </template>
                                </v-simple-table>

                                <v-col class="mt-0 pt-0">
                                    <iframe id="tradingview_6951f" :dark="true" class="bg-main"
                                            src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_5fc02&symbol=BINANCE%3ABTCUSDT&interval=1&symboledit=1&saveimage=1&toolbarbg=f4f7f9&studies=%5B%5D&theme=dark&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&utm_source=crypo-vue.netlify.app&utm_medium=widget&utm_campaign=chart&utm_term=BINANCE%3ABTCUSDT"
                                            style="width: 100%; height: 270px; margin: 0 !important; padding: 0 !important;background-color: #1a212a !important;"
                                            frameborder="0" allowtransparency="true" scrolling="no"
                                            allowfullscreen=""></iframe>
                                </v-col>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
                <v-row>
                    <v-col cols="12" sm="12">
                        <v-tabs v-model="tab1"
                                color="#f0a70a"
                                class="text-capitalize"
                                style="background-color: #1f2833 !important;"
                                grow>
                            <v-tab v-for="item1 in items1"
                                   :key="item1" class="text-capitalize">{{ item1 }}
                            </v-tab>
                        </v-tabs>
                    </v-col>

                </v-row>

                <v-tabs-items v-model="tab1">
                    <v-tab-item
                            v-for="item1 in items1"
                            :key="item1"
                    >
                        <v-container class="pa-0">

                            <v-simple-table class="pb-16" style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                         Datetime
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            Direction
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            Price(USDT)
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            Amount(BTC)
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                    </tr><tr>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                    </tr><tr>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                    </tr><tr>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                    </tr><tr>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                    </tr><tr>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                    </tr><tr>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                        <th class="text-center font-weight-regular pt-3 text--white"  style="color: rgb(255,255,255)">
                                            --
                                        </th>
                                    </tr>


                                    </tbody>
                                </template>
                            </v-simple-table>



                        </v-container>


                    </v-tab-item>
                </v-tabs-items>



            </v-main>
        </v-card>
        <v-bottom-navigation v-model="value" fixed :dark="true" style="background-color: #1f2833 !important;" >
            <v-row class="d-flex justify-space-around align-center pl-2"

            >
                <v-btn  class="pb-3 pt-3 pl-10 pr-10" style="background-color: #00b275 !important;" to="/swap"
                >
                    BuyBTC
                </v-btn>
                <v-btn  class="pb-3 pt-3 pl-10 pr-10" style="background-color:#ce4850 !important;"
                >
                    SellBTC
                </v-btn>
                <v-btn  class="pb-3 pt-3 pl-5 pr-5 font-weight-regular" style="background-color: transparent !important;"
                >
                    Normal
                 <v-icon style="color: #f0a70a">mdi-star</v-icon>

                </v-btn>

            </v-row>
<!--            <v-col cols="12" sm="12">-->
<!--                <v-btn-->

<!--                        style="background-color: #00b275 !important;"-->
<!--                        class="rounded-0">-->
<!--                    CANCEL-->
<!--                </v-btn>-->
<!--                <v-btn-->
<!--                        style="background-color: #f0a70a !important;"-->
<!--                        class="rounded-0">-->
<!--                    OK-->
<!--                </v-btn>-->
<!--                <v-btn-->

<!--                        style="background-color:#1a212a!important;"-->
<!--                        class="rounded-0" large x-large>-->
<!--                    <span style="color: white" class="font-weight-regular"><v-icon-->
<!--                            style="color: #f0a70a">mdi-star</v-icon>-->
<!--                     <br>-->
<!--                        Collected-->
<!--                    </span>-->
<!--                </v-btn>-->
<!--            </v-col>-->


        </v-bottom-navigation>
    </v-app>
</template>

<style>
    .theme--dark.v-tabs > .v-tabs-bar {
        background-color: #1a212a;
    }
    .main-tab-counter  .v-tab{
        min-width: 43px !important;
    }
    /*.v-slide-group__content {*/
    /*    border-bottom: 2px solid black;*/
    /*}*/
    .v-label {
        font-size: 11px;
    }
    .v-input__icon {
        align-items: center;
        display: inline-flex;
        height: 24px;
        flex: 1 0 auto;
        justify-content: center;
        min-width: 8px;
        width: 2px;
    }
    .v-dialog--fullscreen {
        border-radius: 0;
        margin: 0;
        height: auto !important;
        position: fixed;
        overflow-y: auto;
        top: auto !important;
        left: 0;
        bottom: 0 !important;
    }
    .v-slider__tick-label{
        font-size: 11px !important;
    }
    .v-slider--horizontal .v-slider__track-container {
        height: 6px !important;
    }
    .v-slider__tick{
        width: 12px !important;
        height: 12px !important;
        top: calc(56% - 6.5px) !important;
        background: #fff !important;
        border-radius: 50% !important;
        line-height: 2 !important;
    }
    .v-slider__thumb {
        width: 16px !important;
        height: 16px !important;
    }
    .theme--dark.v-slider .v-slider__track-background {
        background: rgb(255, 255, 255) !important;
    }
    .v-slider__thumb::after {
        content: "";
        width: 17px !important;
        height: 16px !important;
        background: #00b275 !important;
        border-radius: 50% !important;
    }
    .v-slider--horizontal .v-slider__tick .v-slider__tick-label {
        top: 14px !important;
    }
    .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child, .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child {
        border-bottom: thin solid transparent;
    }
    .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
        border-bottom: thin solid transparent;
    }
    .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
        border-bottom: thin solid transparent;
    }
</style>

<script>
    export default {
        data: () => ({
            dialog: false,
            drawer: false,
            group: null,
            tab: null,
            tab1:null,
            tab4:null,
            value:[
                '0',
                '10',
                '50',
                '75',
                '100'
            ],
            fruits: [
                '0',
                '10',
                '50',
                '75',
                '100'
            ],
            ticksLabels: [
                '0%',
                '10%',
                '50%',
                '75%',
                '100%'
            ],
            items: [
                'HISTORY', 'KLINE',
            ],

            items1: [
                'DEPTH', 'DEALED',
            ],

            items2: ['Market Price', 'Market Price', 'Market Price', 'Market Price'],

            items4: [
                'FAVOR', 'USDT', 'BTC', 'ETH',
            ],


        }),


    }

</script>