<template>
    <v-main class="pa-0">
        <v-container class="pa-5 d-lg-none d-xl-none d-md-none d-sm-block">
            <v-row>
                <v-carousel class="rounded" :show-arrows="false" height="170">
                    <v-carousel-item
                            v-for="(item,i) in items"
                            :key="i"

                            :src="item.src"
                            reverse-transition="fade-transition"
                            transition="fade-transition"
                    ></v-carousel-item>
                </v-carousel>

                <v-col class="pa-0 mt-2">
                    <div class="d-flex justify-space-between align-center">
                        <v-subheader class="text--white h-30">
                            <small>
                                <v-icon color="rgb(240, 167, 10)" class="mr-1"> mdi-volume-source</v-icon>
                                EXC.CX server update announcement</small>
                        </v-subheader>
                        <v-subheader class=" h-30">
                            <router-link class="text-yellow text-decoration-none" :to="'/message'">More</router-link>

                        </v-subheader>
                    </div>
                </v-col>
            </v-row>

        </v-container>
        <v-divider style="border-width: 6px"></v-divider>
        <v-container>
            <v-row style="margin: 0 !important;">
                <v-col class="col-6 pa-0">
                    <v-card-title class="text--white mt-0 pt-0">
                       My Assets
                    </v-card-title>

                    <v-card-subtitle class="text--white pb-2">
                       Login into View
                    </v-card-subtitle>
                        <v-btn class="text-capitalize ml-4 text-yellow" to="login"
                               elevation="3"
                               outlined
                               height="44"
                               style="border: 1px solid #424950;">
                            Login/ <br>
                            Register
                        </v-btn>

                </v-col>
                <v-col class="col-6 pa-0">
                    <router-link to="myassets">
                        <v-img class="float-right"
                               lazy-src="../assets/img/my-assets.png"
                               max-height="130"
                               max-width="130"
                               src="../assets/img/my-assets.png">
                        </v-img>
                    </router-link>

                </v-col>
            </v-row>
        </v-container>
        <v-divider style="border-width: 1px"></v-divider>
        <v-container>
            <v-row style="margin: 0 !important;">
                <v-col class="col-6 pa-0">
                    <div :dark="true"
                            class="mx-auto">
                        <v-list-item three-line class="d-flex justify-lg-space-between align-center" to="help">
                            <v-list-item-content>
                                <v-list-item-title class="text--white mb-4 font-weight-bold" style="font-size: 0.8rem">
                                    Help
                                </v-list-item-title>
                                <small>
                                    <v-list-item-subtitle class="text--white"><small>Questions/Guides/<br>
                                        Information</small>
                                    </v-list-item-subtitle>
                                </small>
                            </v-list-item-content>

                            <v-avatar class="float-right"
                                    color="white"
                                    size="46"
                            ><v-icon color="#f0a70a">mdi-school</v-icon></v-avatar>
                        </v-list-item>


                    </div>
                </v-col>
                <v-divider
                        vertical
                ></v-divider>
                <v-col class="col-6 pa-0 pl-0">
                    <div :dark="true"
                         class="mx-auto"


                    >
                        <v-list-item three-line class="d-flex justify-lg-space-between align-center" to="announcement">
                            <v-list-item-content>

                                <v-list-item-title class="text--white mb-4 font-weight-bold" style="font-size: 0.8rem">
                                    Announcement
                                </v-list-item-title>
                                <v-list-item-subtitle class="text--white"><small>News/Events/<br>
                                    Information</small></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-avatar class="float-right"
                                      color="white"
                                      size="46"
                            ><v-icon color="#f0a70a">mdi-email-open</v-icon></v-avatar>
                        </v-list-item>


                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-divider style="border-width: 6px"></v-divider>

        <v-container>
            <v-row style="margin: 0 !important;" >
                <v-card class="bg-main"
                        flat
                        tile
                        width="100%"

                >
                    <v-window v-model="onboarding" class="bg-main" style="height: 127px !important;">
                        <v-window-item class="bg-main"
                                v-for="n in length"
                                :key="`card-${n}`"
                        >
                            <v-card
                                    color="transparent"

                            >
                                <v-row
                                        class="bg-main"
                                        align="center"
                                        justify="center"
                                >
                                    <v-card-text class="text-center" >
                                        <v-simple-table style="border:1px solid transparent">
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-center text-body-1" style="color: rgb(255,255,255)">
                                                        BTC/USDT
                                                    </th>
                                                    <th class="text-center text-body-1" style="color: rgb(255,255,255)">
                                                        ETH/USDT
                                                    </th>
                                                    <th class="text-center  text-body-1" style="color: rgb(255,255,255)">
                                                        XPR/USDT
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td  class="text-center">
                                                       <p class="mb-0" style="font-size: 1.3rem;color: #00b275">0.00</p>
                                                        <p class="mb-0" style="color: #00b275">0.00</p>
                                                        <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00CNY</small>
                                                    </td>
                                                    <td  class="text-center">
                                                        <p class="mb-0" style="font-size: 1.3rem;color: #00b275">0.00</p>
                                                        <p class="mb-0" style="color: #00b275">0.00</p>
                                                        <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00CNY</small>
                                                    </td>
                                                    <td  class="text-center">
                                                        <p class="mb-0" style="font-size: 1.3rem;color: #00b275">0.00</p>
                                                        <p class="mb-0" style="color: #00b275">0.00</p>
                                                        <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=0.00CNY</small>
                                                    </td>

                                                </tr>


                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-row>
                            </v-card>
                        </v-window-item>
                    </v-window>

                    <v-card-actions class="justify-center">

                        <v-item-group
                                v-model="onboarding"
                                class="text-center"
                                mandatory
                        >
                            <v-item
                                    v-for="n in length"
                                    :key="`btn-${n}`"
                                    v-slot="{ active, toggle}">
                                <v-btn
                                        :input-value="active"
                                        icon
                                        @click="toggle" >
                                        <v-icon :color="active ? '#f0a70a' : null" >mdi-minus</v-icon>
                                </v-btn>
                            </v-item>
                        </v-item-group>
                    </v-card-actions>
                </v-card>
            </v-row>
            <v-divider style="border-width: 6px"></v-divider>
            <v-simple-table style="border:1px solid transparent" class="pb-16">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-center text-body-1 pt-5 pb-5" style="color: rgb(255,255,255)" colspan="3">
                            Ranking
                        </th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr>

                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                            <div>
                                <v-avatar
                                        color="#00b275"
                                        rounded
                                        size="18" class="mr-4 "
                                        style="color: white"
                                >1
                                </v-avatar>
                            </div>
                            <div class=""><p class="text--white text-body-1 mb-0 " style="font-size: 1.3rem">BTC/USDT</p>
                                <small class="text--disabled pt-3 pb-3" style="color: rgba(255,255,255,0.5) !important;">=24H
                                    0.0000000</small></div>
                        </td>
                        <td  class="text-center pt-3 pb-3">
                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=24H 0.0000000</small>
                        </td>
                        <td  class="text-center pt-3 pb-3">
                            <v-avatar
                                    color="#00b275"
                                    rounded
                                    size="65"
                                    height="25"
                                    style="color: white"
                            >+0.00%</v-avatar>
                        </td>

                    </tr>
                    <tr>

                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                            <div>
                                <v-avatar
                                        color="#00b275"
                                        rounded
                                        size="18" class="mr-4"
                                        style="color: white"
                                >2
                                </v-avatar>
                            </div>
                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem">ETH/USDT</p>
                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=24H
                                    0.0000000</small></div>
                        </td>
                        <td  class="text-center pt-3 pb-3">
                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=24H 0.0000000</small>
                        </td>
                        <td  class="text-center pt-3 pb-3">
                            <v-avatar
                                    color="#00b275"
                                    rounded
                                    size="65"
                                    height="25"
                                    style="color: white"
                            >+0.00%</v-avatar>
                        </td>

                    </tr>
                    <tr>

                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                            <div>
                                <v-avatar
                                        color="#00b275"
                                        rounded
                                        size="18" class="mr-4"
                                        style="color: white"
                                >3
                                </v-avatar>
                            </div>
                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem">XPR/USDT</p>
                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=24H
                                    0.0000000</small></div>
                        </td>
                        <td  class="text-center pt-3 pb-3">
                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=24H 0.0000000</small>
                        </td>
                        <td  class="text-center pt-3 pb-3">
                            <v-avatar
                                    color="#00b275"
                                    rounded
                                    size="65"
                                    height="25"
                                    style="color: white"
                            >+0.00%</v-avatar>
                        </td>

                    </tr>
                    <tr>

                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                            <div>
                                <v-avatar
                                        color="#00b275"
                                        rounded
                                        size="18" class="mr-4"
                                        style="color: white"
                                >4
                                </v-avatar>
                            </div>
                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem">BCH/USDT</p>
                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=24H
                                    0.0000000</small></div>
                        </td>
                        <td  class="text-center pt-3 pb-3">
                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=24H 0.0000000</small>
                        </td>
                        <td  class="text-center pt-3 pb-3">
                            <v-avatar
                                    color="#00b275"
                                    rounded
                                    size="65"
                                    height="25"
                                    style="color: white"
                            >+0.00%</v-avatar>
                        </td>

                    </tr>
                    <tr>

                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                            <div>
                                <v-avatar
                                        color="#00b275"
                                        rounded
                                        size="18" class="mr-4"
                                        style="color: white"
                                >5
                                </v-avatar>
                            </div>
                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem">BSV/USDT</p>
                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=24H
                                    0.0000000</small></div>
                        </td>
                        <td  class="text-center pt-3 pb-3">
                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=24H 0.0000000</small>
                        </td>
                        <td  class="text-center pt-3 pb-3">
                            <v-avatar
                                    color="#00b275"
                                    rounded
                                    size="65"
                                    height="25"
                                    style="color: white"
                            >+0.00%</v-avatar>
                        </td>

                    </tr>
                    <tr>

                        <td  class="text-center d-flex justify-start align-baseline pt-3 pb-3" style="height: 73px;">
                            <div>
                                <v-avatar
                                        color="#00b275"
                                        rounded
                                        size="18" class="mr-4"
                                        style="color: white"
                                >6
                                </v-avatar>
                            </div>
                            <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem">BTC/USDT</p>
                                <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=24H
                                    0.0000000</small></div>
                        </td>
                        <td  class="text-center pt-3 pb-3">
                            <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.0000000</p>
                            <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">=24H 0.0000000</small>
                        </td>
                        <td  class="text-center pt-3 pb-3">
                            <v-avatar
                                    color="#00b275"
                                    rounded
                                    size="65"
                                    height="25"
                                    style="color: white"
                            >+0.00%</v-avatar>
                        </td>

                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-container>

    </v-main>
</template>

<style>
    .container{
        padding: 12px 0 !important;
    }
    .bg-layout{
        background-color: #13161d;
    }
    /*.v-carousel__controls {*/
    /*    height: 34px !important;*/
    /*    background-color: rgba(0, 0, 0, 0.3) !important;*/
    /*    bottom:0 !important;*/
    /*}*/
   /*.v-window{*/
   /*    height: 170px !important;*/
   /*}*/

    /*.v-carousel__controls{*/
    /*    height: 25px !important;*/
    /*    background-color: transparent !important;*/
    /*}*/
    .v-btn--icon.v-size--small .v-icon, .v-btn--fab.v-size--small .v-icon{

        font-size: 5px !important;
    }
    .v-btn--icon.v-size--small{
        width: 5px !important;
        height: 5px !important;
    }
    .v-item-group.v-bottom-navigation{
        right: 0;
    }
    .h-30{
        height: 35px;
    }
    .theme--light.v-data-table {
        background-color: var(--bg-primary) !important;

    }
    /*.theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {*/
    /*    opacity: 0.18 !important;*/
    /*    color: #f0a70a !important;*/
    /*}*/
    .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
        background: transparent !important;
    }
</style>

<script>
    export default {
        data () {

            return {

                length: 2,
                onboarding: 0,
                items: [
                    {
                        src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
                    },
                    {
                        src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
                    },
                    {
                        src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
                    },
                    {
                        src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
                    },
                ],
            }

        },
        methods: {
            next () {
                this.onboarding = this.onboarding + 1 === this.length
                    ? 0
                    : this.onboarding + 1
            },
            prev () {
                this.onboarding = this.onboarding - 1 < 0
                    ? this.length - 1
                    : this.onboarding - 1
            },
        },
    }

</script>