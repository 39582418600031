<template>
    <v-main class="bg-main">
        <v-container class="pa-5">
            <v-row>
                <v-col class="col-12 d-lg-none d-xl-none d-md-none d-sm-block">
                    <div class="d-flex justify-center align-center">
                        <v-avatar size="105" class="mt-16 rounded">
                            <img src="../assets/img/exc-logo.png" class="rounded">
                        </v-avatar>
                    </div>

                    <v-form class="pt-10">
                        <v-text-field
                                label="Username"
                                placeholder="Please Input Your Email"
                                :dark="true"
                                color="#f0a70a"
                        ></v-text-field>
                        <v-text-field class="pt-5"
                                v-model="password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type="show1 ? 'text' : 'password'"
                                name="input-10-1"
                                placeholder="Please Input Your Password"
                                label="Please Input Your Password"
                                :dark="true"
                                hint="At least 8 characters"
                                counter
                                color="#f0a70a"
                                @click:append="show1 = !show1"
                        ></v-text-field>
                        <v-subheader class="pa-0 float-right pb-16 pt-5">
                            <router-link to="forgotpwd" class="text-yellow text-decoration-none ">Forget password?</router-link>
                        </v-subheader>
                        <v-spacer></v-spacer>
                        <v-btn block class="bg-yellow"
                               depressed
                               elevation="2"
                               @click="submit" to="dashboard"
                               large>
                            Login
                        </v-btn>
                    </v-form>

                    <div class="d-flex justify-space-between align-center pt-16">
                        <v-subheader class="text--white pa-0">
                            Don't have an exchange account?
                        </v-subheader>
                        <v-subheader class="pa-0">
                            <router-link  class="text-yellow text-decoration-none" :to="'/register'">Register now</router-link>


                        </v-subheader>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
    export default {
        data() {
            return {
                show1: true,
                show2: true,
                show3: false,
                show4: false,
                password: 'Password',
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    emailMatch: () => (`The email and password you entered don't match`),
                },
            }
        },
    }
</script>
<style lang="sass">


</style>

