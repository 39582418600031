<template>
    <v-main class="bg-main">
        <v-container class="pa-5">
            <v-row>
                <v-col class="col-12 d-lg-none d-xl-none d-md-none d-sm-block">
                    <div class="d-flex justify-center align-center">
                        <v-avatar size="105" class="pt-10">
                            <img src="../assets/img/exc-logo.png" class="rounded-circle">
                        </v-avatar>
                    </div>

                    <v-form class="pt-10">

                        <v-text-field
                                label="Username"
                                placeholder="Please enter  Email address"
                                :dark="true"
                                color="#f0a70a"
                        ></v-text-field>
                        <v-text-field
                                label="Varification code"
                                placeholder="Please enter valid code"
                                :dark="true"
                                color="#f0a70a"
                        ></v-text-field>
                        <v-text-field class="pt-5"
                                      v-model="password"
                                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                      :rules="[rules.required, rules.min]"
                                      :type="show1 ? 'text' : 'password'"
                                      name="input-10-1"
                                      placeholder="Please Input Your Password"
                                      label="Please Input Your Password"
                                      :dark="true"
                                      hint="At least 8 characters"
                                      counter
                                      color="#f0a70a"
                                      @click:append="show1 = !show1"
                        ></v-text-field>
                        <v-text-field class="pt-5"
                                      v-model="password"
                                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                      :rules="[rules.required, rules.min]"
                                      :type="show1 ? 'text' : 'password'"
                                      name="input-10-1"
                                      placeholder="Please Input  Password again"
                                      label="Please Input  Password again"
                                      :dark="true"
                                      hint="At least 8 characters"
                                      counter
                                      color="#f0a70a"
                                      @click:append="show1 = !show1"
                        ></v-text-field>
                        <v-subheader class="text--white pb-5 justify-center">
                            <v-checkbox :dark="true"
                                    v-model="checkbox"

                            ></v-checkbox>
                            I have read and  agree   <a href="#" class="text-yellow text-decoration-none ml-2">  User agreement</a>
                        </v-subheader>
                        <v-spacer></v-spacer>
                        <v-btn block class="bg-yellow text-capitalize"
                               depressed to="login"
                               elevation="2"
                               @click="submit"
                               large>
                            Register
                        </v-btn>


                    </v-form>

                    <div class="d-flex justify-space-between align-center pt-3">
                        <v-subheader class="text--white pa-0">
                            Don't have an exchange account?
                        </v-subheader>
                        <v-subheader class="pa-0">
                             <router-link class="text-yellow text-decoration-none" :to="'/login'">Login Now</router-link>
                        </v-subheader>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
    export default {
        data() {
            return {
                show1: true,
                show2: true,
                show3: false,
                show4: false,
                password: 'Password',
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    emailMatch: () => (`The email and password you entered don't match`),
                },
            }
        },
    }
</script>
<style lang="sass">


</style>

