<template>
    <v-main>
        <v-img src="../assets/img/bg-forgot.jpg" height="100%">
        <v-container class="pa-5">
            <v-row>
                <v-col class="col-12 d-lg-none d-xl-none d-md-none d-sm-block">
                    <div class="d-flex justify-center align-center">
                        <v-avatar size="100" class="pt-10">
                            <img src="../assets/img/exc-logo.png" class="rounded">
                        </v-avatar>
                    </div>

                    <v-form class="pt-10">

                        <v-text-field
                                label="Email"
                                placeholder="Please enter  Email address"
                                :dark="true"
                                color="#f0a70a"
                        ></v-text-field>

                        <v-col cols="12" sm="12" class="pa-0 ma-0 pass-main d-flex justify-center align-center" style="border-bottom: 1px solid #ffffff">
                            <v-text-field
                                    type="number"
                                    label="E-mail varification code"
                                    class="pa-0 pl-0 ma-0"
                                    solo
                                    placeholder="Verification code"
                                    :dark="true"
                                    color="#f0a70a"
                                    @click:append="show1 = !show1">
                            </v-text-field>
                            <v-btn
                                    style="border: 1px solid #ffffff;color: #ffffff"
                                    outlined
                                    rounded
                                    class="text-capitalize pl-1 pr-1 mb-0"
                            >Send Verification</v-btn>
                        </v-col>
                        <v-text-field class="pt-5"
                                      placeholder="Enter New PWD"
                                      label="New PWD"
                                      :dark="true"
                                      hint="At least 8 characters"
                                      counter
                                      color="#f0a70a"
                        ></v-text-field>
                        <v-text-field class="pt-5"

                                      placeholder="Enter Repeat the password"
                                      label="Repeat the password"
                                      :dark="true"
                                      hint="At least 8 characters"
                                      counter
                                      color="#f0a70a"

                        ></v-text-field>
                            <v-spacer></v-spacer>
                        <v-btn block class="text-capitalize mt-16"
                               depressed to="login"
                               elevation="2"
                               style="border: 1px solid white;color: white"
                               outlined
                               rounded
                               @click="submit"
                               large>
                            OK
                        </v-btn>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
        </v-img>
    </v-main>
</template>

<script>
    export default {
        data() {
            return {
                show1: true,
                show2: true,
                show3: false,
                show4: false,
                rules: {
                    required: value => !!value || 'Required.',
                    emailMatch: () => (`The email and password you entered don't match`),
                },
            }
        },
    }
</script>
<style lang="sass">


</style>

