<template>
    <v-app>
        <v-card
                class="overflow-hidden bg-main" :dark="true"
                height="100%"
        >
            <v-app-bar
                    color="bg-main"
                    :dark="true"
                    fixed
                    flat >
                <router-link to="dashboard" class="text-decoration-none">
                    <v-icon class="mr-4">mdi-chevron-left</v-icon>
                </router-link>
                <v-toolbar-title>Transaction</v-toolbar-title>
                <v-spacer></v-spacer>

                    <!-- Modal-->
                <template>
                    <v-row justify="end" >
                        <v-col cols="auto">
                            <v-dialog
                                    transition="dialog-top-transition"
                                    max-width="600"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon light
                                            v-bind="attrs"
                                            v-on="on">
                                    <v-icon color="" class="text--white">
                                        mdi-filter
                                    </v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:default="dialog" :dark="true">
                                    <v-card dark style="background-color: #13161d">
                                        <v-col cols="12" sm="12">
                                            <label for="currency">Currency</label>
                                            <v-select
                                                    class="mt-2 mb-3 rounded-0"
                                                    solo
                                                    :dark="true"
                                                    style="height: 45px !important;background-color: #1f2833;border:0px solid transparent"
                                                    placeholder="Please Enter the currency"
                                                    v-model="select"
                                                    :items="items5"
                                            ></v-select>
                                            <label for="type">Type</label>
                                            <v-select
                                                    class="mt-2 mb-3 rounded-0"
                                                    solo
                                                    :dark="true"
                                                    style="height: 45px !important;background-color: #1f2833;border:0px solid transparent"
                                                    placeholder="Please Enter the currency"
                                                    v-model="select"
                                                    :items="items5"
                                            ></v-select>
                                            <label for="time">Starting and ending time</label>
                                            <br>
                                            <small  class="pl-0 text--white">The starting and ending time is the commission generation time</small>

                                             <v-dialog
                                                        ref="dialog1"
                                                        v-model="modal2"
                                                        :return-value.sync="time"
                                                        persistent
                                                        width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                                class="mt-2 rounded-0 text--white"
                                                                solo
                                                                style="height: 45px !important;background-color: #1f2833;border:0px solid transparent"
                                                                placeholder="Start date"
                                                                v-model="time"

                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-time-picker
                                                            v-if="modal2"
                                                            v-model="time"
                                                            full-width
                                                    >
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                                text
                                                                color="primary"
                                                                @click="modal2 = false"
                                                        >
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                                text
                                                                color="primary"
                                                                @click="$refs.dialog1.save(time)"
                                                        >
                                                            OK
                                                        </v-btn>
                                                    </v-time-picker>
                                                </v-dialog>
                                            <v-dialog
                                                        ref="dialog"
                                                        v-model="modal3"
                                                        :return-value.sync="time3"
                                                        persistent
                                                        width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                                class="mt-2 rounded-0 text--white"
                                                                solo
                                                                style="height: 45px !important;background-color: #1f2833;border:0px solid transparent"
                                                                placeholder="Start date"
                                                                v-model="time3"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-time-picker
                                                            v-if="modal3"
                                                            v-model="time3"
                                                            full-width
                                                    >
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                                text
                                                                color="primary"
                                                                @click="modal3 = false"
                                                        >
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                                text
                                                                color="primary"
                                                                @click="$refs.dialog.save(time3)"
                                                        >
                                                            OK
                                                        </v-btn>
                                                    </v-time-picker>
                                                </v-dialog>

                                        </v-col>

                                        <v-card-actions class="">
                                            <v-col cols="12" sm="12" class="d-flex justify-center align-center">
                                                <v-btn class="text-capitalize  pl-16 pr-16 mr-2"
                                                       style="background-color: #252c34"
                                                       rounded
                                                       text
                                                       @click="dialog.value = true"
                                                >Reset
                                                </v-btn>
                                                <v-btn style="background-color: #f0a70a"
                                                       rounded

                                                       class="text-capitalize pl-16 pr-16"
                                                       text
                                                       @click="dialog.value = true"
                                                >OK
                                                </v-btn>
                                            </v-col>
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>
                        </v-col>
                    </v-row>
                </template>
<!--  Modal end-->
            </v-app-bar>
            <v-main class="bg-main pt-12">
                        <v-container class="pa-0 bg-main">
                            <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                            <v-simple-table class="bg-main"  style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-between">
                                        <th class="text-body-1 pt-3 font-weight-regular"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                            BNB
                                        </th>
                                        <th></th>
                                        <th class="text-body-1 pt-3 font-weight-regular text-right"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                            Done
                                        </th>
                                    </tr>

                                    </thead>

                                    <tbody>
                                    <tr class="justify-space-between">
                                        <td>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Transaction Time</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >2022-22-04 21:10</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Payment fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>
                                        <td class="text-center">
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Type</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >Option Reward</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Discount Fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Amount</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >199.96</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Actual fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>

                                    </tr>
                                    </tbody>
                                </template>

                            </v-simple-table>
                            <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                            <v-simple-table class="bg-main"  style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-between">
                                        <th class="text-body-1 pt-3 font-weight-regular"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                            BNB
                                        </th>
                                        <th></th>
                                        <th class="text-body-1 pt-3 font-weight-regular text-right"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                            Done
                                        </th>
                                    </tr>

                                    </thead>

                                    <tbody>
                                    <tr class="justify-space-between">
                                        <td>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Transaction Time</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >2022-22-04 21:10</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Payment fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>
                                        <td class="text-center">
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Type</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >Option Reward</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Discount Fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Amount</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >199.96</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Actual fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>

                                    </tr>
                                    </tbody>
                                </template>

                            </v-simple-table>
                            <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                            <v-simple-table class="bg-main"  style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-between">
                                        <th class="text-body-1 pt-3 font-weight-regular"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                            BNB
                                        </th>
                                        <th></th>
                                        <th class="text-body-1 pt-3 font-weight-regular text-right"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                            Done
                                        </th>
                                    </tr>

                                    </thead>

                                    <tbody>
                                    <tr class="justify-space-between">
                                        <td>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Transaction Time</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >2022-22-04 21:10</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Payment fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>
                                        <td class="text-center">
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Type</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >Option Reward</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Discount Fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Amount</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >199.96</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Actual fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>

                                    </tr>
                                    </tbody>
                                </template>

                            </v-simple-table>
                            <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                            <v-simple-table class="bg-main"  style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-between">
                                        <th class="text-body-1 pt-3 font-weight-regular"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                            BNB
                                        </th>
                                        <th></th>
                                        <th class="text-body-1 pt-3 font-weight-regular text-right"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                            Done
                                        </th>
                                    </tr>

                                    </thead>

                                    <tbody>
                                    <tr class="justify-space-between">
                                        <td>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Transaction Time</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >2022-22-04 21:10</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Payment fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>
                                        <td class="text-center">
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Type</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >Option Reward</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Discount Fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Amount</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >199.96</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Actual fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>

                                    </tr>
                                    </tbody>
                                </template>

                            </v-simple-table>
                            <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                            <v-simple-table class="bg-main"  style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-between">
                                        <th class="text-body-1 pt-3 font-weight-regular"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                            BNB
                                        </th>
                                        <th></th>
                                        <th class="text-body-1 pt-3 font-weight-regular text-right"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                            Done
                                        </th>
                                    </tr>

                                    </thead>

                                    <tbody>
                                    <tr class="justify-space-between">
                                        <td>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Transaction Time</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >2022-22-04 21:10</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Payment fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>
                                        <td class="text-center">
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Type</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >Option Reward</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Discount Fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Amount</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >199.96</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Actual fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>

                                    </tr>
                                    </tbody>
                                </template>

                            </v-simple-table>
                            <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                            <v-simple-table class="bg-main"  style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-between">
                                        <th class="text-body-1 pt-3 font-weight-regular"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                            BNB
                                        </th>
                                        <th></th>
                                        <th class="text-body-1 pt-3 font-weight-regular text-right"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                            Done
                                        </th>
                                    </tr>

                                    </thead>

                                    <tbody>
                                    <tr class="justify-space-between">
                                        <td>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Transaction Time</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >2022-22-04 21:10</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Payment fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>
                                        <td class="text-center">
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Type</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >Option Reward</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Discount Fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Amount</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >199.96</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Actual fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>

                                    </tr>
                                    </tbody>
                                </template>

                            </v-simple-table>
                            <v-divider class="mt-2" style="border-top:0.5px solid rgba(255,255,255,0.1);"></v-divider>
                            <v-simple-table class="bg-main"  style="border:1px solid transparent">
                                <template v-slot:default>
                                    <thead class="">
                                    <tr class="justify-space-between">
                                        <th class="text-body-1 pt-3 font-weight-regular"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                            BNB
                                        </th>
                                        <th></th>
                                        <th class="text-body-1 pt-3 font-weight-regular text-right"  style="color: rgb(255,255,255);border: 1px solid transparent">
                                            Done
                                        </th>
                                    </tr>

                                    </thead>

                                    <tbody>
                                    <tr class="justify-space-between">
                                        <td>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Transaction Time</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >2022-22-04 21:10</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Payment fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>
                                        <td class="text-center">
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Type</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >Option Reward</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Discount Fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>
                                        <td class="text-right">
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Amount</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >199.96</p>
                                            <p class="mb-1 text--white"  style="font-size:0.7rem;border: 1px solid transparent">Actual fee</p>
                                            <p class="mb-1" color="white" style="font-size: 0.7rem;" >0</p>
                                        </td>

                                    </tr>
                                    </tbody>
                                </template>

                            </v-simple-table>


                        </v-container>
            </v-main>
        </v-card>

    </v-app>
</template>

<style>
    .v-dialog {
       margin: 0 !important;
   }
    .v-dialog__content {
        align-items: start;
        display: flex;
        top: 55px;
    }

</style>

<script>
    export default {
        data: () => ({
            select:null,
            dialog: false,
            drawer: false,
            group: null,
            tab: null,
            tab1:null,
            time: null,
            time3: null,
            menu2: false,
            modal2: false,
            modal3: false,
            menu3: false,
            value:[
                '0',
                '10',
                '50',
                '75',
                '100'
            ],
            fruits: [
                '0',
                '10',
                '50',
                '75',
                '100'
            ],
            ticksLabels: [
                '0%',
                '10%',
                '50%',
                '75%',
                '100%'
            ],
            items: [
                'HISTORY', 'KLINE',
            ],
            items5: [
                'Item 1',
                'Item 2',
                'Item 3',
                'Item 4',
            ],

            items1: [
                'Currency Account', 'Swap Account',
            ],
            items2:
                ['Market Price', 'Market Price', 'Market Price', 'Market Price'],

            items3: [
                { title: 'Recharge Record' },
                { title: 'Withdraw Record' },
            ],
        }),


    }

</script>