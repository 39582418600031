<template>
    <v-app>
        <v-app-bar
                app
                class="bg-main d-lg-none d-xl-none d-md-none d-sm-block"
                dark>
            <div class="d-flex align-center">

            </div>

            <v-spacer></v-spacer>

            <v-btn href="#" text to="setting">
                <span class="mr-2"></span>
                <v-icon color="rgba(255,255,255,0.7)">mdi-cog</v-icon>
            </v-btn>

        </v-app-bar>

        <v-main class="bg-main">
            <v-card dark class="bg-main">
                <v-card-text class="d-flex justify-space-between align-center">

                    <div class="d-flex align-center">
                        <v-img
                                alt="Vuetify Logo"
                                class="shrink mr-2"
                                contain
                                src="https://cdn3.iconfinder.com/data/icons/avatars-round-flat/33/man5-512.png"
                                transition="scale-transition"
                                width="50"
                        />

                        <div>
                            <h3 class="font-weight-regular" style="color: white">Not Login</h3>
                            <spap class="text--white">Welcome to EXC.CX</spap>

                        </div>
                    </div>
                    <div>
                        <router-link to="securitysetting" class="text-decoration-none">
                            <v-icon>
                                mdi-chevron-right
                            </v-icon>
                        </router-link>
                    </div>
                </v-card-text>

            </v-card>
            <v-card dark class="bg-main"
            >
                <v-card-title class="align-center d-flex justify-start pb-7">
                    <v-divider class="mr-1" color="#f0a70a" style="border-width: 2px !important;    margin-top: 5px;
    margin-bottom: 7px;"
                               inset
                               vertical
                    ></v-divider>
                 Assets
                </v-card-title>
                <v-card-text>


                    <v-row class="d-flex justify-space-around align-center">
                        <div class="align-center flex-column">
                            <div align="center" class="pb-1">
                                <v-btn icon :to="'/myassets'">  <v-icon class=""
                                        size="25"
                                        color="#f0a70a">
                                    mdi-hammer
                                </v-icon></v-btn>
                            </div>
                            <div><small>My Assets</small></div>
                        </div>
                        <div class="align-center flex-column">
                            <div align="center" class="pb-1">
                                <v-btn icon :to="'/transition'">  <v-icon
                                        size="25"
                                        color="#f0a70a"
                                >
                                    mdi-transition
                                </v-icon></v-btn>
                            </div>

                            <div><small>Transaction</small></div>
                        </div>
                        <div class="align-center flex-column">
                            <div align="center" class="pb-1">
                                <v-btn icon :to="'/myassets'">  <v-icon
                                        size="25"
                                        color="#f0a70a"
                                >
                                    mdi-account-voice
                                </v-icon></v-btn>
                            </div>

                            <div><small>Online Services</small></div>
                        </div>
                        <div class="align-center flex-column">
                            <div align="center" class="pb-1">
                                <v-btn icon :to="'/myassets'"> <v-icon
                                        size="25"
                                        color="#f0a70a"
                                >
                                    mdi-key-chain
                                </v-icon></v-btn>
                            </div>

                            <div><small>Fund Pwd</small></div>
                        </div>

                    </v-row>

                </v-card-text>

            </v-card>

            <v-divider style="border-width: 6px"></v-divider>
            <v-card dark class="bg-main"
            >
                <div class="d-flex justify-space-between">
                    <v-card-title class="align-center d-flex justify-start pb-7">
                        <v-divider class="mr-1" color="#f0a70a" style="border-width: 2px !important;    margin-top: 8px;
    margin-bottom: 7px;"
                                   inset
                                   vertical
                        ></v-divider>
                        My invitation
                    </v-card-title>
                    <v-card-title class="pb-7 font-weight-regular" style="color:#f0a70a">

                        My invitation link
                    </v-card-title>
                </div>

                <v-card-text>


                    <v-row class="d-flex justify-space-around align-center">
                        <div class="align-center flex-column">
                            <div align="center" class="pb-1">
                                <v-icon class=""
                                        size="25"
                                        color="#fff">
                                    mdi-minus
                                </v-icon>
                            </div>
                            <div><small>First</small></div>
                        </div>
                        <div class="align-center flex-column">
                            <div align="center" class="pb-1">
                                <v-icon class=""
                                        size="25"
                                        color="#fff">
                                    mdi-minus
                                </v-icon>
                            </div>

                            <div>Second</div>
                        </div>
                        <div class="align-center flex-column">
                            <div align="center" class="pb-1">
                                <v-icon class=""
                                        size="25"
                                        color="#fff">
                                    mdi-minus
                                </v-icon>
                            </div>

                            <div><small>Commission</small></div>
                        </div>
                        <div class="align-center flex-column">
                            <div align="center" class="pb-1">
                                <v-icon class=""
                                        size="25"
                                        color="#fff">
                                    mdi-minus
                                </v-icon>
                            </div>

                            <div><small>Level</small></div>
                        </div>

                    </v-row>

                </v-card-text>

            </v-card>
            <v-divider style="border-width: 6px"></v-divider>
            <v-card
                    max-width="95%"
                    class="mx-auto bg-main"
            >

                <v-row dense>

                    <v-col class="pa-0"
                           v-for="(item, i) in items"
                           :key="i"
                           cols="12"
                    >
                        <v-card class="pt-0 pb-3 mb-0"
                                :color="item.color"
                                dark
                        >
                            <div class="d-flex flex-no-wrap justify-space-between  mt-3">
                                <div class="justify-center align-center d-flex flex-column">
                                    <v-card-title
                                            class="text-h5"
                                            v-text="item.title"
                                    ></v-card-title>
                                    <v-card-subtitle v-text="item.artist"></v-card-subtitle>
                                    <v-card-actions>
                                        <v-btn
                                                :to="item.link"
                                                v-if="item.artist === 'Ellie Goulding'"
                                                class="ml-2 mt-3"
                                                fab
                                                icon
                                                height="40px"
                                                right
                                                width="40px"
                                        >
                                            <v-icon>mdi-play</v-icon>
                                        </v-btn>
                                        <v-btn
                                                :to="item.link"
                                                v-else
                                                class="ml-2 text-capitalize bg-yellow"
                                                v-text="item.btn_title"
                                        >
                                        </v-btn>
                                    </v-card-actions>
                                </div>
                                <v-avatar
                                        class="ma-3"
                                        size="40%"
                                        tile
                                >
                                    <v-img :src="item.src"></v-img>
                                </v-avatar>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-divider style="border-width: 6px"></v-divider>
        </v-main>
        <v-bottom-navigation :dark="true" :fixed="true"
                             class="bg-main justify-space-around"
                             color="rgb(240, 167, 10)">
            <v-btn value="home" to="dashboard" width="60">
                <span class="font-weight-regular">Home</span>
                <v-icon>mdi-home</v-icon>
            </v-btn>
            <v-btn value="market" to="market" width="60">
                <span>Market</span>
                <v-icon>mdi-rug</v-icon>
            </v-btn>
            <v-btn value="trade" to="trade">
                <span>Trade</span>
                <v-icon>mdi-strategy</v-icon>
            </v-btn>
            <v-btn value="swap" to="swap">
                <span>Swap</span>
                <v-icon>mdi-swap-horizontal-variant</v-icon>
            </v-btn>
            <v-btn value="option" to="option">
                <span>Option</span>
                <v-icon>mdi-multicast</v-icon>
            </v-btn>
            <v-btn value="my" to="profile">
                <span>My</span>
                <v-icon>mdi-account</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-app>
</template>


<script>
    export default {
        data: () => ({
            items: [
                {
                    color: '#232931',
                    src: require('../assets/img/bg-mytrusted.png'),
                    link:'historical',
                    title: 'MyEntrust',
                    btn_title: 'Currency Trade'
                },
                {
                    color: '#232931',
                    src: require('../assets/img/personal-info.png'),
                    title: 'Security Settings',
                    btn_title: 'Personal Information'
                },

            ],
        }),
    }
</script>