import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index'
import Home from '../views/Home'
import Layout from '../views/Layout'
import Login from '../views/Login'
import Register from '../views/Register'
import Splash from '../views/Splash'
import Dashboard from '../views/Dashboard'
import Profile from '../views/Profile'
import Message from '../views/Message'
import Market from '../views/Market'
import Option from '../views/Option'
import Swap from '../views/Swap'
import Trade from '../views/Trade'
import Help from '../views/Help'
import Announcement from '../views/Announcement'
import Myassets from '../views/Myassets'
import Selectcurrency from '../views/Selectcurrency'
import Deposit from '../views/Deposit'
import Withdrawcurrency from '../views/Withdrawcurrency'
import Withdraw from '../views/Withdraw'
import Btcusdt from '../views/Btcusdt'
import Transition from '../views/Transition'
import Historical from '../views/Historical'
import Securitysetting from '../views/Securitysetting'
import Email from '../views/Email'
import Changepassword from '../views/Changepassword'
import Fundpassword from '../views/Fundpassword'
import Pwdpassword from '../views/Pwdpassword'
import Assettransfer from '../views/Assettransfer'
import Withdrawrecord from '../views/Withdrawrecord'
import Rechargerecord from '../views/Rechargerecord'
import Setting from '../views/Setting'
import Langaugesetting from '../views/Langaugesetting'
import Feedback from '../views/Feedback'
import About from '../views/About'
import Forgotpwd from '../views/Forgotpwd'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '',
        component: Index
      },
      {
        path: '/splash',
        component: Splash
      },
      {
        path: '/login',
        component: Login
      },
      {
        path: '/register',
        component: Register
      },
      {
        path: '/profile',
        component: Profile
      },
      {
        path: '/market',
        component: Market
      },
      {
        path: '/message',
        component: Message
      },
      {
        path: '/option',
        component: Option
      },
      {
        path: '/swap',
        component: Swap
      },
      {
        path: '/trade',
        component: Trade
      },
      {
        path: '/help',
        component: Help
      },
      {
        path: '/announcement',
        component: Announcement
      },
      {
        path: '/myassets',
        component: Myassets
      },
      {
        path: '/selectcurrency',
        component: Selectcurrency
      },
        {
        path: '/withdrawcurrency',
        component: Withdrawcurrency
      },
        {
        path: '/Withdraw',
        component: Withdraw
      },
      {
        path: '/Deposit',
        component: Deposit
      },
      {
        path: '/Btcusdt',
        component: Btcusdt
      },
        {
        path: '/Transition',
        component: Transition
      },
      {
        path: '/Historical',
        component: Historical
      },
      {
        path: '/Securitysetting',
        component: Securitysetting
      },
      {
        path: '/Email',
        component: Email
      },
      {
        path: '/Changepassword',
        component: Changepassword
      },
      {
        path: '/Fundpassword',
        component: Fundpassword
      },
      {
        path: '/Pwdpassword',
        component: Pwdpassword
      },
      {
        path: '/Assettransfer',
        component: Assettransfer
      },
      {
        path: '/Withdrawrecord',
        component: Withdrawrecord
      },
        {
        path: '/Rechargerecord',
        component:Rechargerecord
      },
      {
        path: '/Setting',
        component:Setting
      },
      {
        path: '/Langaugesetting',
        component:Langaugesetting
      },
      {
        path: '/Feedback',
        component:Feedback
      },
      {
        path: '/About',
        component:About
      },
      {
        path: '/Forgotpwd',
        component:Forgotpwd
      },
    ]
  },
  {
    path: '/dashboard',
    component: Layout,
    children: [
      {
        path: '/',
        component: Dashboard
      },
    ]
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
