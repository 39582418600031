<template>
    <v-app class="align-center d-flex">
        <v-app-bar
                app
                class="bg-main d-flex  align-center d-lg-none d-xl-none d-md-none d-sm-block"
                dark>
            <div class="d-flex justify-start">
                <v-btn icon width="25" class="mr-4" :to="'/myassets'">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </div>
            <div class="d-flex justify-center align-center" style="width: 100%">
                <v-toolbar-title align="center" class="font-weight-regular">BNBWithdraw</v-toolbar-title>
            </div>
        </v-app-bar>
        <v-main class="bg-main" style="padding:40px 0">
            <v-container>
                <template>
                    <v-card
                            class="mx-auto"
                            max-width="500"
                            :dark="true"
                            elevation="0"
                    >
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="mb-3 text--white">Available</v-list-item-title>
                                    <v-list-item-title class="font-weight-bold">107.8704441 BTC</v-list-item-title>

                                </v-list-item-content>
                                <!--                                            <v-list-item-action>-->

                                <!--                                                <v-icon  >-->
                                <!--                                                    mdi-chevron-right-->
                                <!--                                                </v-icon>-->
                                <!--                                            </v-list-item-action>-->
                            </v-list-item>
                            <v-divider
                            ></v-divider>
                            <v-list-item >
                                <v-list-item-content>
                                    <v-list-item-title class="mb-0 text--white">Withdraw Address</v-list-item-title>
                                        <v-text-field
                                                solo
                                                prepend-inner-icon=""
                                                class="text--white"
                                                style="padding: 0 !important;max-height:40px !important;min-height: 0 !important; margin-left:-10px !important;border-bottom: 0px none transparent"
                                                label="Please enter or select the address"
                                        >
                                        </v-text-field>
                                </v-list-item-content>
                                <v-list-item-action class="align-self-end">

                                    <v-icon>
                                        mdi-chevron-right
                                    </v-icon>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider
                            ></v-divider>

                            <v-list-item >
                                <v-list-item-content>
                                    <v-list-item-title class="mb-0" style="color:#f0a70a;">Remark (Memo)</v-list-item-title>
                                    <v-text-field
                                            solo
                                            type="number"
                                            prepend-inner-icon=""
                                            class="text--white"
                                            style="padding: 0 !important;max-height:40px !important;min-height: 0 !important; margin-left:-10px !important;border-bottom: 0px none transparent"
                                            label="Please enter the Memo withdrawal"
                                    >
                                    </v-text-field>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider
                            ></v-divider>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="mb-0 text--white">Amount</v-list-item-title>
                                    <v-text-field
                                            solo
                                            type="number"
                                            prepend-inner-icon=""
                                            class="text--white"
                                            style="padding: 0 !important;max-height:35px !important;min-height: 0 !important; margin-left:-10px !important;border-bottom: 0px none transparent"
                                            label="Minimum withdrawal quantity0.0015"
                                    >
                                    </v-text-field>

                                </v-list-item-content>
                                                                            <v-list-item-action class="align-self-end">
                                                                                <v-list-item-title style="color: white">BTC&nbsp;&nbsp;|&nbsp;
                                                                                    <span style="color: #f0a70a">All</span></v-list-item-title>

                                                                            </v-list-item-action>
                            </v-list-item>
                            <v-divider
                            ></v-divider>


                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="mb-0" style="color: white">Miner fee</v-list-item-title>
                                    <v-text-field
                                            solo
                                            type="number"
                                            prepend-inner-icon=""
                                            class="text--white"
                                            style="padding: 0 !important;max-height:35px !important;min-height: 0 !important; margin-left:-10px !important;border-bottom: 0px none transparent"
                                            label="0.000080 ~ 0.00060"
                                    >
                                    </v-text-field>

                                </v-list-item-content>
                            </v-list-item>
                            <v-divider
                            ></v-divider>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="mb-3 text--white">Amount</v-list-item-title>
                                    <v-list-item-title class="font-weight-bold">0.0000</v-list-item-title>

                                </v-list-item-content>
                                <v-list-item-action class="align-self-end">
                                    <v-list-item-title style="color: white">BTC&nbsp;&nbsp;|&nbsp;
                                        <span style="color: #f0a70a">All</span></v-list-item-title>

                                </v-list-item-action>
                            </v-list-item>
                            <v-divider
                            ></v-divider>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="mb-0 text--white">Fund Pwd</v-list-item-title>
                                    <v-text-field
                                            solo
                                            v-model="password"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required, rules.min]"
                                            :type="show1 ? 'text' : 'password'"
                                            name="input-10-1"
                                            label="Fund Pwd"
                                            style="padding: 0 !important;max-height:35px !important;min-height: 0 !important; margin-left:-10px !important;border-bottom: 0px none transparent"
                                            @click:append="show1 = !show1"
                                    ></v-text-field>

                                </v-list-item-content>

                            </v-list-item>
                            <v-divider
                            ></v-divider>

                        </v-list>
                        <v-col cols="12" sm="12" class="bg-main">
                            <v-btn block class="bg-yellow text-capitalize font-weight-regular"

                                   elevation="2"

                                   large
                                   :dark="true"
                            >
                                Submit
                            </v-btn>
                        </v-col>

                    </v-card>
                </template>
            </v-container>
        </v-main>
    </v-app>
</template>

<style>
    /*.theme--dark.v-list {*/
    /*    background: #1a212a;*/
    /*}*/
</style>

<script>
    export default {
        data () {
            return {
                show1: true,

                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    emailMatch: () => (`The email and password you entered don't match`),
                },
            }
        },
    }
</script>