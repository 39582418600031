<template>
    <v-app>
        <v-card
                class="overflow-hidden bg-main" :dark="true"
                height="100%"
        >
            <v-app-bar
                    color="bg-main"
                    dark
            >
                <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
<!--                     <v-icon>mdi-menu-open</v-icon>-->
                <v-toolbar-title>BTC/USDT Option Contract</v-toolbar-title>
            </v-app-bar>

            <v-navigation-drawer :dark="true" class="bg-main"
                    v-model="drawer"
                    absolute
                    temporary
            >
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6">
                           Option Contract
                        </v-list-item-title>

                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list
                        dense
                        nav
                >
                    <v-list-item>

                        <v-list-item-content>
                            <v-list-item-title>BTC <small class="text--white">/USDT</small> Option Contract</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>
                    <v-list-item>

                        <v-list-item-content>
                            <v-list-item-title>ETH <small class="text--white">/USDT</small> Option Contract</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>
                    <v-list-item>

                        <v-list-item-content>
                            <v-list-item-title>LTC <small class="text--white">/USDT</small> Option Contract</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>
                    <v-list-item>

                        <v-list-item-content>
                            <v-list-item-title>DOGE <small class="text--white">/USDT</small> Option Contract</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>
                </v-list>
            </v-navigation-drawer>


        <v-main class="bg-main">
            <v-tabs
                    v-model="tab"
                    color="#f0a70a"
                    background-color="transparent"
                    class="bg-main"
                    grow >
                <v-tab>
                        HISTORY
                </v-tab>
                <v-tab>
                        KLINE
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item class="bg-main">

                    <v-container>
                        <v-row class="pa-3 d-flex justify-space-around align-center">
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>




                        </v-row>
                        <v-row class="pa-3 d-flex justify-space-around align-center">
                            <v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>




                        </v-row>
                        <v-row class="pa-3 d-flex justify-space-around align-center">
                            <v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                            <v-avatar color="red" size="30">
                                <v-icon dark>
                                    mdi-chart-line-variant
                                </v-icon>
                            </v-avatar>
                        </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                            <v-avatar color="green" size="30">
                                <v-icon dark>
                                    mdi-chart-line-variant
                                </v-icon>
                            </v-avatar>
                        </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                            <v-avatar color="red" size="30">
                                <v-icon dark>
                                    mdi-chart-line-variant
                                </v-icon>
                            </v-avatar>
                        </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                        <v-row class="pa-3 d-flex justify-space-around align-center">
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                            <v-avatar color="red" size="30">
                                <v-icon dark>
                                    mdi-chart-line-variant
                                </v-icon>
                            </v-avatar>
                        </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                            <v-avatar color="green" size="30">
                                <v-icon dark>
                                    mdi-chart-line-variant
                                </v-icon>
                            </v-avatar>
                        </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                            <v-avatar color="red" size="30">
                                <v-icon dark>
                                    mdi-chart-line-variant
                                </v-icon>
                            </v-avatar>
                        </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>




                        </v-row>
                        <v-row class="pa-3 d-flex justify-space-around align-center">
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                            <v-avatar color="red" size="30">
                                <v-icon dark>
                                    mdi-chart-line-variant
                                </v-icon>
                            </v-avatar>
                        </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                            <v-avatar color="green" size="30">
                                <v-icon dark>
                                    mdi-chart-line-variant
                                </v-icon>
                            </v-avatar>
                        </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="green" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col><v-col class="col-auto pa-0">
                            <v-avatar color="red" size="30">
                                <v-icon dark>
                                    mdi-chart-line-variant
                                </v-icon>
                            </v-avatar>
                        </v-col>
                            <v-col class="col-auto pa-0">
                                <v-avatar color="red" size="30">
                                    <v-icon dark>
                                        mdi-chart-line-variant
                                    </v-icon>
                                </v-avatar>
                            </v-col>




                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item class="bg-main">
                    <v-container>
                        <iframe id="tradingview_6951f" :dark="true" class="bg-main"
                                src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_5fc02&symbol=BINANCE%3ABTCUSDT&interval=1&symboledit=1&saveimage=1&toolbarbg=f4f7f9&studies=%5B%5D&theme=dark&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&utm_source=crypo-vue.netlify.app&utm_medium=widget&utm_campaign=chart&utm_term=BINANCE%3ABTCUSDT"
                                style="width: 100%; height: 270px; margin: 0 !important; padding: 0 !important;background-color: #1a212a !important;"
                                frameborder="0" allowtransparency="true" scrolling="no"
                                allowfullscreen=""></iframe>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
            <v-divider style="border-width: 4px;border-color: #13161d;"></v-divider>
            <v-tabs   v-model="tab1"
                      color="#f0a70a"
                      class="bg-main  text-capitalize"
                      grow >
                <v-tab v-for="item1 in items1"
                       :key="item1" class="text-capitalize">{{ item1 }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab1">
                <v-tab-item
                        v-for="item1 in items1"
                        :key="item1"
                >
                    <v-container class="pa-0">
                        <v-simple-table style="border:1px solid transparent">

                            <tbody>
                            <tr>
                                <td class="text-center  pt-3 pb-3" colspan="3">
                                    <div><p class="text--white text-body-1 mb-0" style="font-size: 1.3rem">No.394133</p>
                                        <small class="text--disabled" style="color: rgba(255,255,255,0.5) !important;">Time
                                            Range: 2022-02-09 21:47 ~ 2022-02-09 21:48</small></div>
                                </td>
                            </tr>
                            <tr class="ma-5">


                                <td class="text-center pt-1 pb-1" style="border:1px solid #565656">
                                    <p class="mb-0" style="font-size: 1.1rem;color:#00b275">0.00 USDT</p>
                                    <small class="text--disabled" style="color:#00b275 !important;">Buy Renewal Pool</small>
                                </td>
                                <td class="text-center pt-1 pb-1" style="border:1px solid #565656">
                                    <p class="mb-0" style="font-size: 1.1rem;color:#f15057">0.00 USDT</p>
                                    <small class="text--disabled" style="color: #f15057 !important;">Sell Renewal Pool</small>
                                </td>
                                <td class="text-center pt-1 pb-1" style="border:1px solid #565656">
                                    <p class="mb-0" style="font-size: 1.1rem;color:#f0a70a">0.00 USDT</p>
                                    <small class="text--disabled" style="color:#f0a70a !important;">My Bet Amount</small>
                                </td>


                            </tr>


                            </tbody>

                        </v-simple-table>

                    </v-container>

                    <v-container class="bg-main" style="padding-top: 5px !important;">
                        <v-row class="pl-5 pr-5 pt-5">
                            <v-tabs class="main-tab-counter" style="border: 1px solid #565656;border-radius: 50px;min-width: 60px"
                                    :dark="true"

                                    show-arrows
                            >
                                <v-tabs-slider style="background-color: #1a212a;"></v-tabs-slider>

                                <v-tab>
                                   10
                                </v-tab>
                                <v-tab>
                                    50
                                </v-tab>
                                <v-tab>
                                    100
                                </v-tab>
                                <v-tab>
                                    500
                                </v-tab>
                                <v-tab>
                                    1000
                                </v-tab>
                                <v-tab>
                                    2000
                                </v-tab>
                                <v-tab>
                                    5000
                                </v-tab>
                                <v-tab>
                                    10000
                                </v-tab>
                            </v-tabs>

                        </v-row>
                        <v-row justify="center">

                                        <div class="pt-3">
                                            <small class="text--disabled text-center" style="color: rgba(255,255,255,0.5) !important;">
                                                Available Balance: 0.00 USDT
                                            </small></div>


                            <v-col cols="12"
                                    class="pb-16  pl-5 pr-5">
                                <v-btn-toggle
                                        v-model="icon"
                                        borderless
                                >
                                    <v-btn color="rgb(0, 178, 117)" style="width: 100%">
                                        <span style="color: white">PRICE UP</span>
                                    </v-btn>
                                    <v-btn color="rgb(241, 80, 87)" class="pr-0" style="width: 100%">
                                        <span style="color: white">PRICE DOWN</span>
                                    </v-btn>
                                </v-btn-toggle>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>



        </v-main>
        </v-card>
        <v-bottom-navigation :dark="true" :fixed="true"
                             class="bg-main justify-space-around"
                             color="rgb(240, 167, 10)">
            <v-btn value="home" to="dashboard" width="60">
                <span class="font-weight-regular">Home</span>
                <v-icon>mdi-home</v-icon>
            </v-btn>
            <v-btn value="market" to="market" width="60">
                <span>Market</span>
                <v-icon>mdi-rug</v-icon>
            </v-btn>
            <v-btn value="trade" to="trade">
                <span>Trade</span>
                <v-icon>mdi-strategy</v-icon>
            </v-btn>
            <v-btn value="swap" to="swap">
                <span>Swap</span>
                <v-icon>mdi-swap-horizontal-variant</v-icon>
            </v-btn>
            <v-btn value="option" to="option">
                <span>Option</span>
                <v-icon>mdi-multicast</v-icon>
            </v-btn>
            <v-btn value="my" to="profile">
                <span>My</span>
                <v-icon>mdi-account</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-app>
</template>

<style>
    .theme--dark.v-tabs > .v-tabs-bar {
        background-color: #1a212a;
    }
    .main-tab-counter  .v-tab{
        min-width: 43px !important;
    }
</style>

<script>
    export default {
        data: () => ({

            drawer: false,
            group: null,
            tab: null,
            tab1:null,
            items: [
                'HISTORY', 'KLINE',
            ],

            items1: [
                'Trade', 'Current delivery', 'Historical delivery',
            ],
        }),

    }

</script>