<template>
    <v-app class="align-center d-flex">
        <v-app-bar
                app
                class="bg-main d-flex  align-center d-lg-none d-xl-none d-md-none d-sm-block"
                dark >

            <div class="d-flex justify-start">
                <v-btn icon width="25" class="mr-4" :to="'/dashboard'">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </div>
            <div class="d-flex justify-center align-center" style="width: 100%">
                <v-toolbar-title align="center" class="font-weight-regular">Message List</v-toolbar-title>
            </div>

        </v-app-bar>

        <v-main class="bg-main">


                    <v-card
                            class="mx-auto bg-main" :dark="true"
                            max-width="450">
                        <v-list two-line  class="bg-main">
                            <v-list-item-group
                                    v-model="selected"
                                    active-class="bg-main"
                                    multiple
                            >
                                <template v-for="(item, index) in items">
                                    <v-list-item :key="item.title">
                                        <template v-slot:default="{ active }">
                                            <v-list-item-content>
                                                <v-list-item-title v-text="item.title"></v-list-item-title>

                                                <v-list-item-subtitle
                                                        class="text--accent-1"
                                                        v-text="item.headline"
                                                ></v-list-item-subtitle>

                                                <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-list-item-action-text v-text="item.action"></v-list-item-action-text>
                                                <v-icon
                                                        v-if="!active"
                                                        color="grey lighten-1"
                                                >
                                                    mdi-star-outline
                                                </v-icon>

                                                <v-icon
                                                        v-else
                                                        color="yellow darken-3"
                                                >
                                                    mdi-star
                                                </v-icon>
                                            </v-list-item-action>
                                        </template>
                                    </v-list-item>

                                    <v-divider
                                            v-if="index < items.length - 1"
                                            :key="index"
                                    ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card>


        </v-main>
    </v-app>
</template>

<style>
    .theme--dark.v-list {
        background: #1a212a;
    }
</style>

<script>
    export default {
        data: () => ({
            selected: [2],
            items: [
                {
                    action: '15 min',
                    headline: 'This is Test Message',
                    subtitle: `Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...`,
                    title: 'John Doy',
                },
                {
                    action: '15 min',
                    headline: 'This is Test Message',
                    subtitle: `Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...`,
                    title: 'John Doy',
                },
                {
                    action: '15 min',
                    headline: 'This is Test Message',
                    subtitle: `Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...`,
                    title: 'John Doy',
                },
                {
                    action: '15 min',
                    headline: 'This is Test Message',
                    subtitle: `Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...`,
                    title: 'John Doy',
                },
                {
                    action: '15 min',
                    headline: 'This is Test Message',
                    subtitle: `Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...`,
                    title: 'John Doy',
                },
                {
                    action: '15 min',
                    headline: 'This is Test Message',
                    subtitle: `Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...`,
                    title: 'John Doy',
                },
                {
                    action: '15 min',
                    headline: 'This is Test Message',
                    subtitle: `Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...`,
                    title: 'John Doy',
                },
                {
                    action: '15 min',
                    headline: 'This is Test Message',
                    subtitle: `Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...`,
                    title: 'John Doy',
                },
                {
                    action: '15 min',
                    headline: 'This is Test Message',
                    subtitle: `Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown ...`,
                    title: 'John Doy',
                },

            ],
        }),
    }
</script>