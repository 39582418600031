<template>
    <v-app class="align-center d-flex">
        <v-app-bar
                app
                class="bg-main d-flex  align-center d-lg-none d-xl-none d-md-none d-sm-block"
                dark >
            <div class="d-flex justify-start">
                <v-btn icon width="25" class="mr-4" :to="'/profile'">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </div>
            <div class="d-flex justify-center align-center" style="width: 100%">
                <v-toolbar-title align="center" class="font-weight-regular">Security Setting</v-toolbar-title>
            </div>
        </v-app-bar>
        <v-main style="background-color: #13161d">
            <v-container>
                <v-row class="ma-0">
                    <v-col cols="12" sm="12" class="pt-0 pb-2" style="background-color: #13161d">
                        <small class="text--white">To ensure the  security of your account, please Complete the real-name authentication before the transaction operation!</small>
                    </v-col>
                    <v-col cols="12" sm="12" style="padding:0">
                        <v-card
                                class="mx-auto bg-main pt-0 mt-0 rounded"
                                max-width="100%"
                                outlined  >
                            <v-list-item three-line style="min-height: 50px" >
                                <v-list-item-content >
                                    <v-list-item-title style="color: white" >
                                        Personal profile picture
                                    </v-list-item-title>
                                </v-list-item-content>


                                <v-img

                                        alt="Vuetify Logo"
                                        class="shrink"
                                        contain
                                        src="https://cdn3.iconfinder.com/data/icons/avatars-round-flat/33/man5-512.png"
                                        transition="scale-transition"
                                        width="40"
                                />
                                <v-icon class="text--white" size="25">
                                    mdi-chevron-right
                                </v-icon>
                            </v-list-item>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" style="padding: 0"
                    >
                        <v-card class="mx-auto bg-main pt-0 mt-0 rounded" max-width="100%"   outlined  >
                            <v-list-item :to="'email'" three-line style="min-height: 50px">
                                <v-list-item-content>
                                    <v-list-item-title style="color: white" >
                                        Email
                                    </v-list-item-title>
                                </v-list-item-content>
                                <p style="color: #f0a70a" class="ma-auto">
                                    Setted
                                </p>

                                <v-icon class="text--white" size="25">

                                    mdi-chevron-right
                                </v-icon>
                            </v-list-item>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" style="padding: 0">
                        <v-card class="mx-auto bg-main pt-0 mt-0 rounded" max-width="100%"   outlined >
                            <v-list-item :to="'changepassword'" three-line style="min-height: 50px">
                                <v-list-item-content>
                                    <v-list-item-title style="color: white">
                                        Login password
                                    </v-list-item-title>
                                </v-list-item-content>
                                <p style="color: #f0a70a" class="ma-auto">
                                    Modify
                                </p>
                                <v-icon class="text--white" size="25">
                                    mdi-chevron-right
                                </v-icon>
                            </v-list-item>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" style="padding: 0">
                        <v-card class="mx-auto bg-main pt-0 mt-0 rounded" max-width="100%"   outlined  >
                            <v-list-item :to="'fundpassword'" three-line style="min-height: 50px" >
                                <v-list-item-content >
                                    <v-list-item-title style="color: white" >
                                        FundPwd
                                    </v-list-item-title>
                                </v-list-item-content>
                                <p style="color: #f0a70a" class="ma-auto">
                                    Modify
                                </p>
                                <v-icon class="text--white" size="25">
                                    mdi-chevron-right
                                </v-icon>
                            </v-list-item>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" class="mt-3" style="padding: 0">
                        <v-card class="mx-auto bg-main pt-0 mt-0 rounded" max-width="100%"   outlined  >
                            <v-list-item three-line style="min-height: 30px" >
                                <v-list-item-content >
                                    <v-list-item-title style="color: white" >
                                        Gesture password
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-switch
                                         class="mt-3"
                                        v-model="switch2"
                                        inset
                                ></v-switch>
                                <v-icon class="text--white" size="25">
                                    mdi-chevron-right
                                </v-icon>
                            </v-list-item>
                        </v-card>
                    </v-col>

                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<style>
    .v-input--switch--inset .v-input--switch__track {
        border-radius: 5px !important;
        height: 20px !important;
        left: -4px;
        opacity: 1;
        top: calc(80% - 14px) !important;
    }
    .v-input--switch__thumb {
        border-radius: 22% !important;
        top: calc(80% - 10px) !important;
        height: 13px !important;
        position: relative;
        width: 13px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }

</style>

<script>
    export default {
        data () {
            return {
                switch2: false,
            }
        },
    }
</script>