import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

// import HelloWorld from './components/HelloWorld';

// Vue.use(VueRouter)


Vue.config.productionTip = false

// const routes = [
//   { path: '/home', component: HelloWorld },
//
// ];
//
// const router = new VueRouter({
//   routes
// })

new Vue({
  vuetify,
  router,

  // router,
  render: h => h(App)
}).$mount('#app')
