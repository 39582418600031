<template style="position:relative;">
    <v-main class="bg-main">
        <v-img src="../assets/img/splash.png"></v-img>
    </v-main>
</template>
<script>
    export default {
        created() {
            setTimeout(function() {
                this.$router.push({path: "/login"});
            }.bind(this), 3000);
        }
    }
</script>